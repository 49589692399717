import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// components
import CreateGroupForm from '../../../components/group/wa/CreateGroupForm';
// sections
import { useSettingsContext } from '../../../theme/sections/settings';
import CustomBreadcrumbs from '../../../theme/sections/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function CreateWaGroupPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Create Whatsapp Group | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create a new whatsapp group"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Whatsapp Group',
              href: '/group',
            },
            {
              name: 'Create',
            },
          ]}
        />

        <CreateGroupForm />
      </Container>
    </>
  );
}
