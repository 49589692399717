import { format, getTime, formatDistanceToNow, isToday } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}
export function fDateYMD(date, newFormat) {
  const fm = newFormat || 'yyyy-MM-dd';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTime(date, newFormat) {
  const fm = newFormat || 'p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export const wDateTime = (timestamp) => {
  const messageDate = new Date(timestamp);
  const currentDate = new Date();

  // Check if the date is today
  if (isToday(messageDate)) {
    // If today, only show the time
    return format(messageDate, 'h:mm a');
  } else {
    // If not today, show the full date and time
    return format(messageDate, 'dd/MM/yy h:mm a');
  }
};
