import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import { groupApi } from '../services/groupApi';
import { waGroupApi } from '../services/waGroupApi';
import { memberApi } from '../services/memberApi';
import { waMemberApi } from '../services/waMemberApi';
import { messageApi } from '../services/messageApi';
import { reportApi } from '../services/reportApi';
import { chatApi } from '../services/chatApi';
import { dashboardApi } from '../services/dashboardApi';
import { autoResponseApi } from '../services/autoResponseApi';
import { quickReplyApi } from '../services/quickReplyApi';
import { supportApi } from '../services/supportApi';
import { subscriberApi } from '../services/subscriberApi';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [waGroupApi.reducerPath]: waGroupApi.reducer,
    [memberApi.reducerPath]: memberApi.reducer,
    [waMemberApi.reducerPath]: waMemberApi.reducer,
    [messageApi.reducerPath]: messageApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [autoResponseApi.reducerPath]: autoResponseApi.reducer,
    [quickReplyApi.reducerPath]: quickReplyApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [subscriberApi.reducerPath]: subscriberApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      groupApi.middleware,
      waGroupApi.middleware,
      memberApi.middleware,
      waMemberApi.middleware,
      messageApi.middleware,
      chatApi.middleware,
      autoResponseApi.middleware,
      quickReplyApi.middleware,
      dashboardApi.middleware,
      reportApi.middleware,
      supportApi.middleware,
      subscriberApi.middleware
    ),
});
