// Utility function to check if a user has the required permissions
const checkPermission = (permissions, requiredPermissions) => {
  return requiredPermissions.every((permission) =>
    permissions.some((userPermission) => userPermission.guard_name === permission)
  );
};

export function hasPermission(permissions, navConfig) {
  return navConfig
    .map((section) => {
      if (section.items) {
        // Create a new section object with filtered items
        const filteredSection = {
          ...section,
          items: section.items
            .map((item) => {
              // if item has guard and permission
              if (checkPermission(permissions, [item.guard])) {
                if (item.children) {
                  // If the item has children, filter the children based on permissions
                  item.children = item.children.filter((child) =>
                    checkPermission(permissions, [child.guard])
                  );
                  // Only return the item if it has children after filtering
                  if (item.children.length > 0) {
                    return item;
                  }
                }
                return item;
              }

              return null;
            })
            .filter((item) => item !== null),
        };

        // Return the new section object if it has items after filtering
        return filteredSection.items.length > 0 ? filteredSection : null;
      }

      // Return the original section if it doesn't have items
      return null;
    })
    .filter((section) => section !== null);
}
