import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const memberApi = createApi({
  reducerPath: 'memberApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/members',
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    //credentials: 'include', // This allows server to set cookies
  }),
  tagTypes: ['Member'],
  endpoints: (builder) => ({
    // Get member by group id
    members: builder.query({
      query: (id) => '/' + id,
      providesTags: ['Member'],
    }),

    // add member
    addMember: builder.mutation({
      query: (member) => ({
        url: `/add/${member.group_id}`,
        method: 'POST',
        body: member,
      }),
      invalidatesTags: ['Member'],
    }),

    // delete member
    deleteMember: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Member'],
    }),

    // update member
    updateMember: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Member'],
    }),
  }),
});

export const {
  useMembersQuery,
  useAddMemberMutation,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
} = memberApi;
