import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  Typography,
  Paper,
  Fab,
  Card,
  Container,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// sections
import { useSettingsContext } from 'src/theme/sections/settings';
import { fDate, fDateTime, fTime } from 'src/theme/utils/formatTime';
import Iconify from 'src/theme/sections/iconify/Iconify';
import CustomBreadcrumbs from 'src/theme/sections/custom-breadcrumbs/CustomBreadcrumbs';
import { useSnackbar } from '../../theme/sections/snackbar';
// react
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    //height: '80vh',
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0',
  },
  messageArea: {
    height: '55vh',
    overflowY: 'auto',
  },
  recipientArea: {
    height: '80vh',
    overflowY: 'auto',
  },
});

const Chat = () => {
  const classes = useStyles();
  const { themeStretch } = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Helmet>
        <title> Support: Chat | Notify92</title>
      </Helmet>

      <Container maxWidth={themeStretch ? true : 'xlg'}>
        <CustomBreadcrumbs
          heading="Chat"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Support',
              href: 'Support',
            },
            {
              name: 'Chat',
            },
          ]}
        />
        <Grid container component={Paper} className={classes.chatSection}>
          <Grid item xs={5} sm={6} md={4} lg={3} className={classes.borderRight500}>
            {/* {isFetching ? 'fetching' : 'nope'} */}
            <List className={classes.recipientArea}>
              <ListItem>
                <ListItemText primary={<Typography variant="h4">Contacts</Typography>} />
              </ListItem>
              <Divider />

              <ListItem>
                <ListItemText primary={<Typography variant="h6">No Recipent</Typography>} />
              </ListItem>

              <div key={123}>
                <ListItem button sx={{ p: 2 }}>
                  <ListItemText
                    primary={<Typography variant="h6">Zehra</Typography>}

                    // secondary={
                    //   recipient.message?.length >= 30
                    //     ? recipient.message?.substring(0, 30) + '...'
                    //     : recipient.message?.substring(0, 30)
                    // }
                  />
                  {/* <ListItemText align="right" secondary={fTime(recipient.createdAt)} /> */}
                  <ListItemText
                    align="right"
                    secondary={
                      <Iconify icon="mdi:user-circle" sx={{ color: 'primary.main' }} width={30} />
                    }
                  />
                </ListItem>
                <Divider />
              </div>
            </List>
          </Grid>
          <Grid item xs={7} sm={6} md={8} lg={9}>
            {/* {chatFetching ? 'fetching' : 'nope'} */}
            <List>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="h6">Recipient: 03324400643</Typography>}
                />
              </ListItem>
            </List>
            <Divider />
            <List className={classes.messageArea}>
              <Typography variant="h4" align="center" sx={{ py: 10 }}>
                No Data
              </Typography>

              <div>
                <ListItem key={1} sx={{ py: 2 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ListItemText
                        align="left"
                        primary={
                          <Typography
                            sx={{
                              p: 1.5,
                              minWidth: 48,
                              maxWidth: 400,
                              borderRadius: 1,
                              overflow: 'hidden',
                              typography: 'body2',
                              bgcolor: 'primary.lighter',
                            }}
                            variant="body2"
                          >
                            hello world
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ListItemText align="left" secondary="11:00pm" />
                    </Grid>
                  </Grid>
                </ListItem>
              </div>
            </List>

            <Divider />
            <Grid container style={{ padding: '20px' }}>
              <Grid item xs={10}>
                <TextField value="message" label="Type Something" fullWidth />
              </Grid>
              <Grid xs={2} sx={{ px: 1, py: 0.5 }}>
                <Button variant="contained" color="primary" size="large">
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Chat;
