import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';

// @mui
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Button,
  Stack,
  Typography,
  Card,
  Divider,
  CardHeader,
  CardContent,
  Box,
} from '@mui/material';

// sections
import { useSettingsContext } from '../../theme/sections/settings';
import Iconify from 'src/theme/sections/iconify/Iconify';
import Scrollbar from 'src/theme/sections/scrollbar/Scrollbar';

// components
import { DataActivity, WidgetSummary } from '../../components/dashboard/analytics';
import InvoiceAnalytic from 'src/components/dashboard/analytics/InvoiceAnalytic';
import AnalyticsWidgetSummary from 'src/components/dashboard/analytics/AnalyticsWidgetSummary';
import Statistics from 'src/components/dashboard/analytics/Statistics';

//rtk
import {
  useTodayQuery,
  useMonthQuery,
  useMonthGraphQuery,
  useTodayFailedQuery,
  useSubscribersQuery,
} from 'src/redux/services/dashboardApi';

// utils
import { fDate } from 'src/theme/utils/formatTime';
import PieChartWidget from 'src/components/dashboard/analytics/PieChartWidget';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

export default function AnalyticalDashboardPage() {
  const theme = useTheme();
  const { themeStretch } = useSettingsContext();
  const [date, setDate] = useState([]);
  const [text, setText] = useState([]);
  const [image, setImage] = useState([]);
  const [video, setVideo] = useState([]);
  const [document, setDocument] = useState([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  // rtk
  const { data: today, isLoading, isSuccess } = useTodayQuery();
  const {
    data: failed,
    isLoading: failedLoading,
    isSuccess: failedSuccess,
  } = useTodayFailedQuery();
  const {
    data: subscribers,
    isLoading: subscibersLoading,
    isSuccess: subscibersSuccess,
  } = useSubscribersQuery();
  const { data: month, isLoading: monthLoading, isSuccess: monthSuccess } = useMonthQuery();
  const { data: mg, isLoading: mgLoading, isSuccess: mgSuccess } = useMonthGraphQuery();

  useEffect(() => {
    if (mgSuccess) {
      setDate(mg.map(({ date }) => fDate(date)));
      setText(mg.map(({ text }) => text));
      setImage(mg.map(({ image }) => image));
      setVideo(mg.map(({ video }) => video));
      setDocument(mg.map(({ document }) => document));
    }
  }, [mgSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setTotal(today.text + today.image + today.video + today.document + today.audio);
    }
  }, [isSuccess]);

  const TIME_LABELS = {
    month: date,
  };

  const Percentage = (value) => {
    return Number((value / total) * 100).toFixed();
  };

  const handleOutgoingClick = () => {
    navigate('/report/logs/out-message');
  };

  const handleIngoingClick = () => {
    navigate('/report/logs/in-message');
  };

  return (
    <>
      <Helmet>
        <title> Analytical Dashboard | {process.env.REACT_APP_NAME} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} lg={7} onClick={handleOutgoingClick}>
            <Card>
              <CardHeader title="Today's outgoing count" subheader={isLoading && 'Loading....'} />
              <CardContent>
                <Grid container>
                  {isSuccess && (
                    <>
                      <Grid item xs={12} md={6} lg={6}>
                        <PieChartWidget
                          title="Current Visits"
                          total={total}
                          chart={{
                            series: [
                              {
                                label: 'Text',
                                value: isSuccess && total != 0 ? Percentage(today.text) : 0,
                                icon: 'mdi:message-text',
                              },
                              {
                                label: 'Video',
                                value: isSuccess && total != 0 ? Percentage(today.video) : 0,
                                icon: 'mdi:message-video',
                              },
                              {
                                label: 'Image',
                                value: isSuccess && total != 0 ? Percentage(today.image) : 0,
                                icon: 'mdi:message-image',
                              },
                              {
                                label: 'Document',
                                value: isSuccess && total != 0 ? Percentage(today.document) : 0,
                                icon: 'mdi:message-bookmark',
                              },
                              {
                                label: 'Audio',
                                value: isSuccess && total != 0 ? Percentage(today.audio) : 0,
                                icon: 'material-symbols:voice-chat',
                              },
                            ],
                            colors: [
                              theme.palette.secondary.main,
                              theme.palette.warning.main,
                              theme.palette.error.main,
                              theme.palette.primary.main,
                              theme.palette.info.main,
                            ],
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <Statistics
                          total={70}
                          chart={{
                            series: 76,
                          }}
                          data={[
                            {
                              name: 'Text',
                              value: isSuccess ? today.text : 0,
                              color: 'secondary.main',
                              icon: <Iconify icon="mdi:message-text" />,
                            },
                            {
                              name: 'Video',
                              value: isSuccess ? today.video : 0,
                              color: 'warning.main',
                              icon: <Iconify icon="mdi:message-video" />,
                            },
                            {
                              name: 'Image',
                              value: isSuccess ? today.image : 0,
                              color: 'error.main',
                              icon: <Iconify icon="mdi:message-image" />,
                            },
                            {
                              name: 'Document',
                              value: isSuccess ? today.document : 0,
                              color: 'primary.main',
                              icon: <Iconify icon="mdi:message-bookmark" />,
                            },
                            {
                              name: 'Audio',
                              value: isSuccess ? today.audio : 0,
                              color: 'info.main',
                              icon: <Iconify icon="material-symbols:voice-chat" />,
                            },
                          ]}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={12} lg={12}>
                    <Divider sx={{ mt: 1.5 }} />
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
                      <Box sx={{ pt: 1, width: 1, textAlign: 'center' }}>
                        <Typography sx={{ typography: 'subtitle2', color: 'text.secondary' }}>
                          Limit Exceeded
                        </Typography>
                        {/* <Typography sx={{ mb: 1, typography: 'caption', color: 'error.main' }}>
                          (Failed)
                        </Typography> */}
                        <Typography sx={{ typography: 'h6', color: 'error.main' }}>
                          {failedSuccess ? failed.limit_exceeded : 0}
                        </Typography>
                      </Box>

                      <Box sx={{ pt: 1, width: 1, textAlign: 'center' }}>
                        <Typography sx={{ typography: 'subtitle2', color: 'text.secondary' }}>
                          No Whatsapp Account
                        </Typography>
                        {/* <Typography sx={{ mb: 1, typography: 'caption', color: 'error.main' }}>
                          (Failed)
                        </Typography> */}
                        <Typography sx={{ typography: 'h6', color: 'error.main' }}>
                          {failedSuccess ? failed.not_whatsapp : 0}
                        </Typography>
                      </Box>
                      <Box sx={{ pt: 1, width: 1, textAlign: 'center' }}>
                        <Typography sx={{ typography: 'subtitle2', color: 'text.secondary' }}>
                          Invalid Numbers
                        </Typography>
                        {/* <Typography sx={{ mb: 1, typography: 'caption', color: 'error.main' }}>
                          (Failed)
                        </Typography> */}
                        <Typography sx={{ typography: 'h6', color: 'error.main' }}>
                          {failedSuccess ? failed.invalid_number : 0}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={5} lg={5} onClick={handleIngoingClick}>
            <Card>
              <CardHeader title="Today's Incoming Count" />
              <CardContent>
                <Box display="grid" gap={2} gridTemplateColumns="repeat(2, 1fr)">
                  <AnalyticsWidgetSummary
                    title="Auto Response"
                    total={isSuccess ? today.autoresponse : 0}
                    color="secondary"
                    icon="fluent-mdl2:chat-bot"
                  />

                  <AnalyticsWidgetSummary
                    title="Message"
                    total={isSuccess ? today.incomingMessage : 0}
                    icon="tabler:message-2-down"
                    color="success"
                  />

                  <AnalyticsWidgetSummary
                    title="Audio Call"
                    total={isSuccess ? today.incomingAudioCall : 0}
                    color="warning"
                    icon="solar:call-chat-rounded-outline"
                  />

                  <AnalyticsWidgetSummary
                    title="Video Call"
                    total={isSuccess ? today.incomingVideoCall : 0}
                    color="error"
                    icon="fluent:chat-video-20-regular"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={10} xs={12} md={10} onClick={handleOutgoingClick}>
            <Card>
              <CardHeader title="Monthly Count" />
              <Scrollbar>
                <Stack
                  direction="row"
                  divider={
                    <Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />
                  }
                  sx={{ pb: 1 }}
                >
                  <InvoiceAnalytic
                    title={monthLoading ? 'Loading...' : 'Text'}
                    total={monthSuccess ? month.text : 0}
                    percent={100}
                    price={345}
                    icon="mdi:message-text"
                    color={theme.palette.primary.main}
                  />
                  <InvoiceAnalytic
                    title={monthLoading ? 'Loading...' : 'Video'}
                    total={monthSuccess ? month.video : 0}
                    percent={100}
                    price={345}
                    icon="mdi:message-video"
                    color={theme.palette.warning.main}
                  />

                  <InvoiceAnalytic
                    title={monthLoading ? 'Loading...' : 'Image'}
                    total={monthSuccess ? month.image : 0}
                    percent={213123}
                    price={123321}
                    icon="mdi:message-image"
                    color={theme.palette.error.main}
                  />

                  <InvoiceAnalytic
                    title={monthLoading ? 'Loading...' : 'Document'}
                    total={monthSuccess ? month.document : 0}
                    percent={22313213}
                    price={1313221}
                    icon="mdi:message-bookmark"
                    color={theme.palette.secondary.main}
                  />

                  <InvoiceAnalytic
                    title={monthLoading ? 'Loading...' : 'Auto-Response'}
                    total={monthSuccess ? month.autoresponse : 0}
                    percent={123213}
                    price={31231}
                    icon="ri:chat-smile-fill"
                    color={theme.palette.info.main}
                  />

                  {/* <InvoiceAnalytic
                    title={monthLoading ? 'Loading...' : 'In-Message'}
                    total={monthSuccess ? month.incoming : 0}
                    percent={13221}
                    price={21332123}
                    icon="ri:chat-download-fill"
                    color={theme.palette.grey[500]}
                  /> */}
                </Stack>
              </Scrollbar>
            </Card>
          </Grid>

          <Grid item lg={2} xs={12} md={2}>
            <AnalyticsWidgetSummary
              title={subscibersLoading ? 'Loading...' : 'Subscribers'}
              total={subscibersSuccess ? subscribers : 0}
              icon="clarity:users-line"
              color="info"
              sx={{ mt: '6px' }}
            />
          </Grid>

          {mgSuccess && (
            <Grid item xs={12} md={12} lg={12} onClick={handleOutgoingClick}>
              <DataActivity
                title="Past Fortnight Activity"
                chart={{
                  labels: TIME_LABELS,
                  colors: [
                    theme.palette.primary.main,
                    theme.palette.error.main,
                    theme.palette.warning.main,
                    theme.palette.text.disabled,
                  ],
                  series: [
                    {
                      type: 'Month',
                      data: [
                        { name: 'Texts', data: text },
                        { name: 'Images', data: image },
                        { name: 'Videos', data: video },
                        { name: 'Documents', data: document },
                      ],
                    },
                  ],
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}
