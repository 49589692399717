import PropTypes from 'prop-types';
import { useState } from 'react';
import { sentenceCase } from 'change-case';
// @mui
import {
  Stack,
  Button,
  TableRow,
  Checkbox,
  MenuItem,
  TableCell,
  IconButton,
  Chip,
  Avatar,
} from '@mui/material';
// utils
import { fDate } from '../../../theme/utils/formatTime';
import { fCurrency } from '../../../theme/utils/formatNumber';
// sections
import Label from '../../../theme/sections/label';
import Image from '../../../theme/sections/image';
import Iconify from '../../../theme/sections/iconify';
import MenuPopover from '../../../theme/sections/menu-popover';
import ConfirmDialog from '../../../theme/sections/confirm-dialog';

// ----------------------------------------------------------------------

GroupViewTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function GroupViewTableRow({ row, selected, onViewRow }) {
  const { transactionalId, sender, receipient, type, message, logs, status, createdAt } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell>{transactionalId}</TableCell>

      <TableCell>{sender}</TableCell>

      <TableCell>{receipient}</TableCell>

      {/* <TableCell>
        <Chip
          variant="soft"
          label={members}
          color="info"
          onClick={onViewRow}
          sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
        />
      </TableCell> */}

      <TableCell>{type}</TableCell>

      <TableCell>{message}</TableCell>

      <TableCell>{logs}</TableCell>

      <TableCell>
        <Label variant="soft" color={status == 0 ? 'default' : 'success'}>
          {status == 0 ? 'Processing' : 'Processed'}
        </Label>
      </TableCell>

      <TableCell>{fDate(createdAt)}</TableCell>
    </TableRow>
  );
}
