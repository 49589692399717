import { useTheme, styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import FormProvider, {
  RHFAutocomplete,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from 'src/theme/sections/hook-form';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// MUI
import {
  Box,
  Card,
  Container,
  IconButton,
  Stack,
  Drawer,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Typography,
  InputBase,
  InputAdornment,
  Avatar,
  Button,
  Divider,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  ButtonBase,
  Fab,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import Label from 'src/theme/sections/label/Label';
//sections
import Image from 'src/theme/sections/image';
import { CustomAvatar } from 'src/theme/sections/custom-avatar';
import MenuPopover from 'src/theme/sections/menu-popover/MenuPopover';
import { useSnackbar } from 'src/theme/sections/snackbar';
import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/theme/sections/iconify';
import Scrollbar from 'src/theme/sections/scrollbar';
import { useSettingsContext } from 'src/theme/sections/settings';
import ConfirmDialog from 'src/theme/sections/confirm-dialog/ConfirmDialog';
import { wDateTime } from 'src/theme/utils/formatTime';
//context
import { useSocket } from 'src/context/SocketContext';
//components
import PendingChats from 'src/components/support/PendingChats';
//rtk
import { useQuickRepliesQuery } from 'src/redux/services/quickReplyApi';
import { minWidth } from '@mui/system';

// ----------------------------------------------------------------------

export default function SupportPage() {
  const { themeStretch } = useSettingsContext();

  const theme = useTheme();

  const [openPopover, setOpenPopover] = useState(null);

  const [openQuickPopover, setOpenQuickPopover] = useState(false);

  const { enqueueSnackbar } = useSnackbar(); // toast

  const { emit, connected, newMessage, newChatList, statusMessage, newChat } = useSocket();

  const userUrl = `${process.env.PUBLIC_URL}/assets/profile/user_dp.png`;

  const StyledToggleButton = styled((props) => <IconButton disableRipple {...props} />)(
    ({ theme }) => ({
      left: 0,
      zIndex: 9,
      width: 32,
      height: 32,
      position: 'absolute',
      top: theme.spacing(13),
      borderRadius: `0 12px 12px 0`,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      boxShadow: theme.customShadows.primary,
      '&:hover': {
        backgroundColor: theme.palette.primary.darker,
      },
    })
  );

  const [openNav, setOpenNav] = useState(false);

  const isDesktop = useResponsive('up', 'md');

  const isCollapse = isDesktop && !openNav;

  const NAV_WIDTH = 400;

  const NAV_COLLAPSE_WIDTH = 96;

  const scrollRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(-1);

  const fileInputRef = useRef(null);

  const [message, setMessage] = useState('');

  const [users, setUsers] = useState(null);

  const [chatStatus, setChatStatus] = useState(null);

  const [chatList, setChatList] = useState({ list: [] });

  const [chatDetails, setChatDetails] = useState({ chat: [] });

  const [pendingList, setPendingList] = useState({ list: [] });

  const [selectedItem, setSelectedItem] = useState(null);

  const [selectedContact, setSelectedContact] = useState(null);

  const [currentLastIndex, setCurrentLastIndex] = useState(0);

  const [newOpen, setNewOpen] = useState(0);

  const [scrollPosition, setScrollPosition] = useState(0);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openDialogue, setOpenDialogue] = useState(false);

  const [quickReplies, setQuickReplies] = useState([]);

  const [isSlash, setIsSlash] = useState(false);

  // rtk
  const { data: quickReply, error, isLoading, isSuccess } = useQuickRepliesQuery();
  useEffect(() => {
    if (isSuccess) {
      const combinedQuickReplies = [
        { title: 'Welcome', message: 'Welcome! How may I help you?' },
        ...quickReply,
      ];
      setQuickReplies(combinedQuickReplies);
    }
  }, [quickReply, isSuccess]);

  // open transfer dialogue
  const handleOpenDialogue = () => {
    setOpenDialogue(true);
  };
  // delete confirm dialogue
  const handleCloseDialogue = () => {
    reset();
    setOpenDialogue(false);
  };

  // delete confirm dialogue
  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  // delete confirm dialogue
  const handleCloseConfirm = () => {
    reset2();
    setOpenConfirm(false);
  };

  // pop over menu
  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };
  // pop over menu
  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  // pop over quick reply menu
  const handleOpenQuickPopover = (event) => {
    setOpenQuickPopover(event.currentTarget);
  };
  // pop over quick reply menu
  const handleCloseQuickPopover = () => {
    setOpenQuickPopover(null);
  };

  // chat side navigation
  const handleToggleNav = () => {
    setOpenNav(!openNav);
  };
  // chat side navigation
  const handleOpenNav = () => {
    setOpenNav(true);
  };
  // chat side navigation
  const handleCloseNav = () => {
    setOpenNav(false);
  };

  // chat side navigation {responsiveness}
  useEffect(() => {
    if (!isDesktop) {
      handleCloseNav();
    } else {
      handleOpenNav();
    }
  }, [isDesktop]);

  useEffect(() => {
    if (connected == true) {
      emit('chatList', 'data', (response) => {
        setChatList(response);
      });

      emit('pendingList', 'data', (response) => {
        setPendingList(response);
      });

      emit('getParentUsers', 'data', (response) => {
        setUsers(response);
      });
    }
  }, [connected]);

  const handleContactClick = (list) => {
    setNewOpen(0);
    setCurrentLastIndex(0);
    setScrollPosition(0);

    if (list.unread > 0 || list.transferred == 1) {
      emit('markRead', { id: list.id, number: list.number }, (response) => {
        const updatedListWithUnreadZero = chatList.list.map((item) =>
          item.id === list.id ? { ...item, unread: 0, transferred: 0 } : item
        );
        setChatList({ ...chatList, list: updatedListWithUnreadZero });
      });
    }

    emit('chatStatus', list.id, (response) => {
      setChatStatus(response);
    });

    emit('chatDetails', { id: list.id, number: list.number, lastID: 0 }, (response) => {
      setChatDetails(response);
      // Set the last chat ID
      if (response.chat.length > 0) {
        setCurrentLastIndex(response.chat[0].id);
      }
    });

    // Set the selected item
    setSelectedItem(list.id);
    // Set the selected contact number
    setSelectedContact(list.number);
  };

  const handleKeyPress = (event) => {
    //if (event.key === 'Enter') {
    //event.preventDefault();
    emit('sendMessage', { number: selectedContact, message: message }, (response) => {
      // Update chatDetails with the new message
      setChatDetails((prevChatDetails) => ({
        ...prevChatDetails,
        chat: [
          ...prevChatDetails.chat,
          { id: response.transactionId, message: message, self: 'OUT', timestamp: new Date() },
        ],
      }));
    });

    // Update chatList with the new outgoing message
    setChatList((prevChatList) => {
      const updatedList = (prevChatList.list || []).map((item) =>
        item.number === selectedContact ? { ...item, message: message, unread: 0 } : item
      );
      return { list: updatedList };
    });

    setMessage('');
    //}
  };

  useEffect(() => {
    if (newMessage.message?.length > 0) {
      setChatList((prevChatList) => {
        const contactIndex = prevChatList.list.findIndex(
          (item) => item.number === newMessage.number
        );

        const updatedList =
          contactIndex !== -1
            ? [
                {
                  ...prevChatList.list[contactIndex],
                  message: newMessage.message,
                  unread: 1,
                  timestamp: new Date(),
                },
                ...prevChatList.list.slice(0, contactIndex),
                ...prevChatList.list.slice(contactIndex + 1),
              ]
            : [
                {
                  number: newMessage.number,
                  message: newMessage.message,
                  unread: 1,
                  timestamp: new Date(),
                },
                ...prevChatList.list,
              ];

        return { list: updatedList };
      });

      if (selectedItem !== null && selectedContact === newMessage.number) {
        setChatDetails((prevChatDetails) => ({
          ...prevChatDetails,
          chat: [
            ...prevChatDetails.chat,
            {
              id: newMessage.id,
              message: newMessage.message,
              self: 'IN',
              timestamp: new Date(),
            },
          ],
        }));
      }
    }
  }, [newMessage]);

  useEffect(() => {
    if (newChat.message?.length > 0) {
      setPendingList((prevPendingList) => {
        // Check if prevPendingList.list is an array
        if (Array.isArray(prevPendingList.list)) {
          // Update pendingList.list with the new chat, adding it to the beginning
          return { ...prevPendingList, list: [newChat, ...prevPendingList.list] };
        } else {
          // If prevPendingList.list is not an array, create a new array with newChat
          return { ...prevPendingList, list: [newChat] };
        }
      });
    }
  }, [newChat]);

  useEffect(() => {
    setChatList(newChatList);
  }, [newChatList]);

  useEffect(() => {
    if (statusMessage) {
      // Find the message in chatDetails and update its status
      setChatDetails((prevChatDetails) => {
        const updatedChat = prevChatDetails.chat.map((message) =>
          message.id === statusMessage.message_log_id
            ? { ...message, status: statusMessage.status }
            : message
        );
        return { ...prevChatDetails, chat: updatedChat };
      });
    }
  }, [statusMessage]);

  useEffect(() => {
    if (chatDetails && newOpen == 0) {
      if (scrollRef.current) {
        const scrollContainer = scrollRef.current;
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  }, [chatDetails]);

  const MessageStatusIcon = ({ messageStatus }) => {
    let icon;
    let color;
    switch (messageStatus) {
      case 'Pending':
        icon = 'mdi:clock-outline';
        break;
      case 'Sent':
        icon = 'mdi:check';

        break;
      case 'Delivered':
        icon = 'mdi:check-all';

        break;
      case 'Seen':
        icon = 'mdi:check-all';
        color = 'info.main';
        break;
      default:
        icon = '';
    }

    return <Iconify icon={icon} sx={{ color: color, width: 18 }} />;
  };

  const handleLoadMoreMessages = () => {
    // Save the current scroll position before loading more messages
    setScrollPosition(scrollRef.current.scrollHeight - scrollRef.current.scrollTop);

    setNewOpen(1);
    if (selectedContact && currentLastIndex > 0) {
      // Load more messages using currentLastIndex
      emit(
        'chatDetails',
        { id: selectedItem, number: selectedContact, lastID: currentLastIndex },
        (response) => {
          // Append the new messages to the existing chatDetails
          setChatDetails((prevChatDetails) => ({
            ...prevChatDetails,
            chat: [...response.chat, ...prevChatDetails.chat],
          }));

          // Update the currentLastIndex for the next load
          if (response.chat.length > 0) {
            setCurrentLastIndex(response.chat[0].id);
          }

          // Calculate the new scroll position after loading more messages
          if (scrollRef.current) {
            const scrollContainer = scrollRef.current;
            scrollContainer.scrollTop = scrollContainer.scrollHeight - scrollPosition;
          }
        }
      );
    }
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const scrollContainer = scrollRef.current;
      if (scrollContainer.scrollTop === 0) {
        // User has scrolled to the top
        // Load more messages
        handleLoadMoreMessages();
      }
    }
  };

  const handleAddChat = (e, id) => {
    e.preventDefault();
    emit('acceptChat', id, (response) => {
      //refresh list after accepting chat
      setChatList(response.chatList);
      setPendingList(response.pendingList);
    });
    enqueueSnackbar(`Chat has been added to the contact list.`);
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    toUserId: Yup.string().required('Please select recipient.'),
    status: Yup.string().required('Please select one of the status.'),
  });

  const defaultValues = {
    toUserId: '',
    status: 'Low Priority',
  };

  // functions to build form returned by useForm() and useFieldArray() hooks
  const methods = useForm({ resolver: yupResolver(validationSchema), defaultValues });
  const { control, handleSubmit, formState, reset } = methods;
  const { errors } = formState;

  const onSubmit = (data) => {
    data.listId = selectedItem;
    data.lastChatId =
      chatDetails.chat.length > 0 ? chatDetails.chat[chatDetails.chat.length - 1].id : null;

    emit('transferChat', data, (response) => {
      //refresh list after accepting chat
      setChatList(response.chatList);
      setPendingList(response.pendingList);
      setChatDetails({ chat: [] });
      setSelectedContact(null);
      setSelectedItem(null);
    });

    reset();

    setOpenDialogue(false);

    enqueueSnackbar('Chat has been transferred.');
  };

  const OPTIONS = [
    { value: 'Solved', label: 'Solved' },
    { value: 'Closed', label: 'Closed' },
    { value: 'Other', label: 'Other' },
  ];

  // form validation rules
  const validationSchema2 = Yup.object().shape({
    status: Yup.string().required('Status is required.'),
    reason: Yup.string()
      .when('status', {
        is: 'Other',
        then: Yup.string().required('This is required.'),
      })
      .max(250, 'It must be at most 250 characters'),
  });

  const defaultValues2 = {
    reason: '',
    status: 'Solved',
  };

  // functions to build form returned by useForm() and useFieldArray() hooks
  const methods2 = useForm({ resolver: yupResolver(validationSchema2), defaultValues2 });
  const { handleSubmit: handleSubmit2, watch, reset: reset2 } = methods2;
  const status = watch('status');

  const handleEndChat = (data) => {
    data.listId = selectedItem; //current opened chat
    data.reason = data.status == 'Other' ? data.reason : data.status;
    //reset form
    reset2();
    //close dialogue
    setOpenConfirm(false);

    emit('endChat', data, (response) => {
      //refresh lists after end chat
      setChatList(response.chatList);
      setPendingList(response.pendingList);
      setChatDetails({ chat: [] });
      setSelectedContact(null);
      setSelectedItem(null);
    });
    //notify
    enqueueSnackbar('Chat has been ended.');
  };

  const renderContent = (
    <>
      {/* Account Details */}
      <Box
        sx={{
          p: 2.3,
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          backgroundColor: 'primary.main',
          color: 'white',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center">
          {!isCollapse && (
            <>
              <Typography variant="h4">Chats</Typography>
              {/* <Avatar src={userUrl} sx={{ cursor: 'pointer', width: 48, height: 48 }} /> */}

              <Box sx={{ flexGrow: 1 }} />
            </>
          )}
          <Fab color="default" onClick={handleToggleNav} size="small">
            <Iconify
              icon={openNav ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'}
              width={20}
            />
          </Fab>
        </Stack>
      </Box>
      {/* Contact list */}
      <Scrollbar>
        <List disablePadding>
          {chatList &&
            chatList.list &&
            chatList.list.map((list) => (
              <>
                <ListItemButton
                  disableGutters
                  sx={{
                    py: 1.5,
                    px: 2.5,
                    bgcolor: selectedItem === list.id ? 'action.selected' : 'initial',
                  }}
                  key={list.message_log_id}
                  onClick={() => handleContactClick(list)}
                >
                  <ListItemAvatar>
                    {/* individual */}
                    <Badge
                      color="info"
                      overlap="circular"
                      badgeContent={isCollapse && list.unread > 0 ? list.unread : 0}
                    >
                      {list.transferred === 1 ? (
                        <Badge
                          overlap="circular"
                          badgeContent={
                            <Iconify icon={'fluent:chat-arrow-back-16-filled'} color="error.main" />
                          }
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                          <Avatar src={userUrl} sx={{ width: 48, height: 48 }} />
                        </Badge>
                      ) : (
                        <Avatar src={userUrl} sx={{ width: 48, height: 48 }} />
                      )}
                    </Badge>
                  </ListItemAvatar>

                  {openNav && (
                    <>
                      <ListItemText
                        primary={list.number}
                        primaryTypographyProps={{ noWrap: true, variant: 'subtitle1' }}
                        secondary={
                          <>
                            {list.transferred === 1 && (
                              <Label color="error" sx={{ mr: '5px' }}>
                                Transferred
                              </Label>
                            )}

                            {list.message}
                          </>
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          variant: list.unread > 0 ? 'subtitle2' : 'body1',
                          color: list.unread > 0 ? 'text.primary' : 'text.secondary',
                        }}
                      />

                      <Stack alignItems="flex-end" sx={{ ml: 2, height: 60 }}>
                        <Typography
                          noWrap
                          variant="caption"
                          component="span"
                          sx={{
                            mb: 1.5,
                            fontSize: 12,
                            color: 'text.disabled',
                          }}
                        >
                          {wDateTime(list.timestamp)}
                        </Typography>

                        {list.unread > 0 && <Label color="info">{list.unread}</Label>}
                      </Stack>
                    </>
                  )}
                </ListItemButton>
              </>
            ))}
        </List>
      </Scrollbar>
    </>
  );

  return (
    <>
      <Helmet>
        <title> Support | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : 'xlg'}>
        <Card sx={{ height: '80vh', display: 'flex', boxShadow: 10 }}>
          {!isDesktop && (
            <StyledToggleButton onClick={handleToggleNav}>
              <Iconify width={16} icon="eva:people-fill" />
            </StyledToggleButton>
          )}
          {isDesktop ? (
            <Drawer
              open={openNav}
              variant="persistent"
              PaperProps={{
                sx: {
                  pb: 1,
                  width: 1,
                  position: 'static',
                  ...(isCollapse && {
                    transform: 'none !important',
                    visibility: 'visible !important',
                  }),
                },
              }}
              sx={{
                width: NAV_WIDTH,
                transition: theme.transitions.create('width'),
                ...(isCollapse && {
                  width: NAV_COLLAPSE_WIDTH,
                }),
              }}
            >
              {renderContent}
            </Drawer>
          ) : (
            <Drawer
              open={openNav}
              onClose={handleCloseNav}
              ModalProps={{ keepMounted: true }}
              PaperProps={{
                sx: {
                  pb: 1,
                  width: NAV_WIDTH,
                },
              }}
            >
              {renderContent}
            </Drawer>
          )}

          <Stack flexGrow={1} sx={{ overflow: 'hidden' }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                p: (theme) => theme.spacing(2, 1, 2, 2),
              }}
            >
              {/* Header */}
              {selectedItem ? (
                <>
                  <Stack flexGrow={1} direction="row" alignItems="center" spacing={2}>
                    <Avatar src={userUrl} sx={{ width: 45, height: 45 }} />
                    <div>
                      <Typography variant="subtitle1">{selectedContact}</Typography>
                      <Typography variant="subtitle2">{chatStatus}</Typography>
                    </div>
                  </Stack>
                  <IconButton
                    color={openPopover ? 'primary' : 'default'}
                    onClick={handleOpenPopover}
                  >
                    <Iconify icon="eva:more-vertical-fill" width={24} />
                  </IconButton>
                  <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top">
                    <MenuItem
                      onClick={() => {
                        handleClosePopover();
                        handleOpenDialogue();
                      }}
                    >
                      <Iconify icon="fluent:chat-arrow-back-20-regular" />
                      Transfer Chat
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        handleOpenConfirm();
                        handleClosePopover();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon="lucide:message-circle-x" />
                      End Chat
                    </MenuItem>
                  </MenuPopover>
                </>
              ) : (
                <Stack flexGrow={1} direction="row" alignItems="center" spacing={2}>
                  <CustomAvatar src="/" alt="Watify" name="Watify" color="primary" />
                  <div>
                    <Typography variant="subtitle2">Welcome to help desk!</Typography>

                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      <Box component="span" sx={{ textTransform: 'capitalize' }}>
                        Online Support to Customers
                      </Box>
                    </Typography>
                  </div>
                </Stack>
              )}
            </Stack>
            <Stack
              direction="row"
              flexGrow={1}
              sx={{
                overflow: 'hidden',
                borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            >
              <Stack
                flexGrow={1}
                sx={{
                  minWidth: 0,
                }}
              >
                {/* chat details */}

                <Scrollbar
                  scrollableNodeProps={{
                    ref: scrollRef,
                    onScroll: handleScroll,
                  }}
                  sx={{
                    p: 3,
                    height: 1,
                    //backgroundImage: 'url("/assets/images/wa.jpg")',
                    //backgroundSize: 'auto',
                  }}
                >
                  {chatDetails &&
                    chatDetails.chat &&
                    chatDetails.chat.map((chat) => (
                      <>
                        <Stack
                          direction="row"
                          justifyContent={chat.self == 'OUT' ? 'flex-end' : 'unset'}
                          sx={{
                            mb: 3,
                          }}
                          key={chat.message_log_id}
                        >
                          <Stack
                            spacing={1}
                            alignItems={chat.self == 'OUT' ? 'flex-end' : 'flex-start'}
                          >
                            <Stack
                              sx={{
                                p: 1.5,
                                minWidth: 48,
                                maxWidth: 320,
                                borderRadius: 1,
                                overflow: 'hidden',
                                typography: 'body1',
                                bgcolor: 'info.lighter',
                                color: 'grey.800',
                                ...(chat.self == 'OUT' && {
                                  color: 'grey.800',
                                  bgcolor: 'primary.lighter',
                                }),
                              }}
                            >
                              {chat.message}
                              <Typography
                                noWrap
                                variant="subtitle2"
                                sx={{
                                  color: 'text.disabled',
                                }}
                              >
                                {wDateTime(chat.timestamp)} &nbsp;
                                <MessageStatusIcon messageStatus={chat.status} />
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        {chat.chatAssignLogId != null && chat.chatAssignLogId != 0 && (
                          <Stack
                            sx={{
                              p: 0.5,
                              borderRadius: 1,
                              overflow: 'hidden',
                              typography: 'body1',
                              bgcolor: 'grey.300',
                              color: 'grey.900',
                              textAlign: 'center',
                            }}
                          >
                            <Typography>
                              Chat has been transferred from {chat.assignFromUser} on{' '}
                              {chat.assignStatus}
                            </Typography>
                          </Stack>
                        )}
                      </>
                    ))}
                </Scrollbar>

                {/* input message */}
                {selectedItem && (
                  <>
                    <InputBase
                      multiline={true}
                      rows={2}
                      value={message}
                      onChange={(event) => {
                        setMessage(event.target.value);
                        // open quick message if press "/"
                        event.target.value === '/' ? (
                          <>
                            {setIsSlash(true)}
                            {handleOpenQuickPopover(event)}
                          </>
                        ) : (
                          setIsSlash(false)
                        );
                      }}
                      //onKeyDown={handleKeyPress}
                      placeholder="Type a message"
                      startAdornment={
                        <>
                          <MenuPopover
                            open={openQuickPopover}
                            onClose={handleCloseQuickPopover}
                            arrow={isSlash ? 'bottom-left' : 'right-bottom'}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="subtitle2">Quick Reply</Typography>
                              <IconButton
                                size="small"
                                component={RouterLink}
                                to="/quick/reply/create"
                              >
                                <Iconify
                                  icon="zondicons:add-outline"
                                  color="primary.main"
                                  width={17}
                                />
                              </IconButton>
                            </div>
                            <Divider />
                            {isSuccess &&
                              quickReplies.map((quickReply) => (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      handleCloseQuickPopover();
                                      setMessage(quickReply.message);
                                    }}
                                    key={quickReply.id}
                                  >
                                    <div>
                                      <Typography variant="caption">{quickReply.title}</Typography>
                                      <Typography variant="body2">
                                        {quickReply.message.length > 50
                                          ? `${quickReply.message.substring(0, 50)}...`
                                          : quickReply.message}
                                      </Typography>
                                    </div>
                                  </MenuItem>
                                  <Divider />
                                </>
                              ))}
                          </MenuPopover>
                          <InputAdornment
                            position="start"
                            color={openQuickPopover ? 'primary' : 'default'}
                            onClick={handleOpenQuickPopover}
                          >
                            <IconButton size="small">
                              <Iconify icon="material-symbols:quickreply-outline" />
                            </IconButton>
                          </InputAdornment>
                        </>
                      }
                      endAdornment={
                        <InputAdornment position="start" onClick={handleKeyPress}>
                          <Button size="small" color="primary" variant="contained">
                            <Iconify icon="mingcute:send-fill" />
                            Send
                          </Button>
                        </InputAdornment>
                      }
                      sx={{
                        pl: 1,
                        height: 70,
                        flexShrink: 0,
                        borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
                      }}
                    />
                  </>
                )}
              </Stack>

              {/* Pendind Chats */}
              <PendingChats
                pendingList={pendingList}
                userUrl={userUrl}
                handleAddChat={handleAddChat}
              />
            </Stack>
          </Stack>
        </Card>
      </Container>

      {/* <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="End Chat"
        content="Are you sure want to end the chat?"
        action={
          
            <Button variant="contained" color="error" onClick={handleEndChat}>
              Yes, Terminate
            </Button>
          
        }
      /> */}

      <Dialog fullWidth open={openConfirm} maxWidth="sm">
        <FormProvider methods={methods2} onSubmit={handleSubmit2(handleEndChat)}>
          <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>End Chat</DialogTitle>
          <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
            <Stack spacing={2}>
              <Typography variant="subtitle2">Are you sure want to end the chat?</Typography>

              <Stack>
                <Typography variant="body2">
                  Please select one of the following statuses:
                </Typography>
                <RHFRadioGroup
                  row
                  spacing={4}
                  name="status"
                  options={OPTIONS}
                  value={status || ''}
                />
              </Stack>
              {status === 'Other' && (
                <RHFTextField
                  name="reason"
                  label="Please define the other status"
                  multiline
                  rows={3}
                />
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" type="submit">
              End Chat
            </Button>
            <Button onClick={handleCloseConfirm}>Cancel</Button>
          </DialogActions>
        </FormProvider>
      </Dialog>

      <Dialog fullWidth open={openDialogue} maxWidth="sm">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>Transfer Chat</DialogTitle>
          <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
            <Stack spacing={4}>
              <RHFSelect native name="toUserId" label="Transfer to:" placeholder="Transfer to:">
                <option value="" />
                {users &&
                  users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
              </RHFSelect>

              <Stack>
                <Typography variant="subtitle2">Select status:</Typography>

                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Box gap={2} display="grid" gridTemplateColumns="repeat(3, 1fr)">
                      {[
                        {
                          label: 'Low Priority',
                          image: (
                            <Avatar src={'/assets/images/lp.png'} sx={{ width: 50, height: 50 }} />
                          ),
                        },
                        {
                          label: 'Medium Priority',
                          image: (
                            <Avatar src={'/assets/images/mp.png'} sx={{ width: 50, height: 50 }} />
                          ),
                        },
                        {
                          label: 'High Priority',
                          image: (
                            <Avatar src={'/assets/images/hp.png'} sx={{ width: 50, height: 50 }} />
                          ),
                        },
                      ].map((item) => (
                        <Paper
                          component={ButtonBase}
                          variant="outlined"
                          key={item.label}
                          onClick={() => field.onChange(item.label)}
                          sx={{
                            p: 2.5,
                            borderRadius: 1,
                            typography: 'subtitle2',
                            flexDirection: 'column',
                            ...(item.label === field.value && {
                              borderWidth: 2,
                              borderColor: 'primary.main',
                            }),
                          }}
                        >
                          {item.image}
                          {item.label}
                        </Paper>
                      ))}
                    </Box>
                  )}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="soft" type="submit">
              Submit
            </Button>
            <Button onClick={handleCloseDialogue}>Cancel</Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}
