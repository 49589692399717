import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Card,
  Table,
  Button,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
// redux rtk
import {
  useLocalGroupsQuery,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} from '../../../redux/services/groupApi';
// sections
import { useSnackbar } from '../../../theme/sections/snackbar';
import { useSettingsContext } from '../../../theme/sections/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../theme/sections/table';
import Iconify from '../../../theme/sections/iconify';
import Scrollbar from '../../../theme/sections/scrollbar';
import CustomBreadcrumbs from '../../../theme/sections/custom-breadcrumbs';
import ConfirmDialog from '../../../theme/sections/confirm-dialog';
// sections
import { GroupTableRow, GroupTableToolbar } from '../../../components/group/local/list';
import { Stack } from '@mui/system';
import FormProvider, { RHFTextField, RHFSwitch } from '../../../theme/sections/hook-form';
import { useForm } from 'react-hook-form';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Group', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'member_count', label: 'No. of Members', align: 'left' },
  { id: 'creation_time', label: 'Create at', align: 'left' },
  { id: '', label: '' },
];

// ----------------------------------------------------------------------

export default function ListGroupPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'createdAt',
  });

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState('');

  const [openConfirm, setOpenConfirm] = useState(false);

  const { enqueueSnackbar } = useSnackbar(); // toast

  // rtk
  const { data, error, isLoading, isSuccess } = useLocalGroupsQuery();
  const [deleteGroup] = useDeleteGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();

  useEffect(() => {
    if (isSuccess) {
      setTableData(data);
    }
  }, [data, isSuccess]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const denseHeight = dense ? 60 : 80;

  const isFiltered = filterName !== '';

  const isNotFound = (!dataFiltered.length && !!filterName) || (isLoading && !dataFiltered.length);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteRow = (id) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }
    deleteGroup(id);
    enqueueSnackbar('Group has been deleted successfully');
  };

  const handleDeleteRows = (selectedRows) => {
    const deleteRows = tableData.filter((row) => !selectedRows.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);

    if (page > 0) {
      if (selectedRows.length === dataInPage.length) {
        setPage(page - 1);
      } else if (selectedRows.length === dataFiltered.length) {
        setPage(0);
      } else if (selectedRows.length > dataInPage.length) {
        const newPage = Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) - 1;
        setPage(newPage);
      }
    }
  };

  const handleViewRow = (id, name, description) => {
    navigate('member/' + id, { state: { groupName: name, description: description } });
  };

  const handleResetFilter = () => {
    setFilterName('');
  };

  const [open, setOpen] = useState(false);
  const handleEditRow = (row) => {
    setOpen(true);
    setValue('id', row.id);
    setValue('name', row.name);
    setValue('description', row.description);
    setValue('adminOnly', row.adminOnly == 1 ? true : false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Group name is required'),
    description: Yup.string().required('Description is required'),
  });

  // functions to build form returned by useForm() and useFieldArray() hooks
  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const { control, setValue, setError, handleSubmit, formState, clearErrors, reset } = methods;
  const { errors } = formState;

  const onSubmit = (data) => {
    const isAdminOnly = data.adminOnly == false ? 0 : 1;

    updateGroup({
      id: data.id,
      name: data.name,
      description: data.description,
      adminOnly: isAdminOnly,
    });

    setOpen(false);
    enqueueSnackbar('Group has been updated successfully');
    //dispatch(getGroups());
  };
  return (
    <>
      <Helmet>
        <title> Local Groups | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Local Groups"
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            {
              name: 'Local Groups',
              href: '/groups',
            },
            { name: 'List' },
          ]}
          action={
            <Button
              component={RouterLink}
              to="/group/create"
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Group
            </Button>
          }
        />

        <Card>
          <GroupTableToolbar
            filterName={filterName}
            onFilterName={handleFilterName}
            isFiltered={isFiltered}
            onResetFilter={handleResetFilter}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  // onSelectAllRows={(checked) =>
                  //   onSelectAllRows(
                  //     checked,
                  //     tableData.map((row) => row.id)
                  //   )
                  // }
                />

                <TableBody>
                  {(isLoading ? [...Array(rowsPerPage)] : dataFiltered)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) =>
                      row ? (
                        <GroupTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row)}
                          onViewRow={() => handleViewRow(row.id, row.name, row.description)}
                        />
                      ) : (
                        !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      )
                    )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            Delete
          </Button>
        }
      />
      <Dialog fullWidth open={open} maxWidth="sm">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}> Edit Group </DialogTitle>
          <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
            <Stack spacing={3}>
              <input name="id" type="hidden" onChange={(e) => setValue('id', e.target.value)} />
              <RHFTextField
                name="name"
                label="Group Name"
                onChange={(e) => setValue('name', e.target.value)}
              />
              <RHFTextField
                name="description"
                label="Description"
                multiline
                rows={3}
                onChange={(e) => setValue('description', e.target.value)}
              />
              <RHFSwitch
                name="adminOnly"
                labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Admin Only
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Restrict to only admin can message.
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                onChange={(e) => setValue('adminOnly', e.target.checked)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="soft" type="submit">
              Save
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filterName }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (data) => data.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return inputData;
}
