import * as Yup from 'yup';
import { useEffect } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, Typography, Button, Divider, Box, Alert } from '@mui/material';
import { useSnackbar } from '../../theme/sections/snackbar';
import FormProvider, { RHFTextField } from '../../theme/sections/hook-form';
// @rtk
import { useCreateQuickReplyMutation } from 'src/redux/services/quickReplyApi';

// ----------------------------------------------------------------------

export default function CreateQuickReplyForm() {
  // form validation rules
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title should be given to the message.').max(200),
    message: Yup.string().required('Message field is required.').max(450),
  });

  // default values for useForm
  const defaultValues = {
    title: '',
    message: '',
  };
  // functions to build form returned by useForm() and useFieldArray() hooks
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { control, setValue, setError, handleSubmit, formState, clearErrors, reset } = methods;
  const { errors } = formState;

  // rtk
  const [createQuickReply, result] = useCreateQuickReplyMutation();
  // form submission
  const onSubmit = (data) => {
    createQuickReply(data);
  };

  // initialization
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (result.error) {
      enqueueSnackbar(
        result.error.data?.message ? result.error.data?.message : result.error.error,
        {
          variant: 'error',
        }
      );
      setError('afterSubmit', {
        message: result.error.data?.message ? result.error.data?.message : result.error.error,
      });
      setTimeout(() => clearErrors(), 10000);
    }
    if (result.isSuccess) {
      enqueueSnackbar('Quick Reply has been created successfully');
      reset();
    }
  }, [result]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={2}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

              <RHFTextField name="title" label="Write title for the quick reply" />

              <RHFTextField name="message" label="Type in your quick reply" multiline rows={3} />
            </Stack>

            <Divider light sx={{ mt: 3 }} />

            <Stack direction="row" spacing={1.5} sx={{ mt: 3 }} justifyContent="flex-end">
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                loading={result.isLoading}
              >
                Create Quick Reply
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
