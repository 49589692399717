import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Link,
  Stack,
  Button,
  Divider,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
} from '@mui/material';
// utils
import { fDateTime } from '../../../../theme/utils/formatTime';
import { fCurrency } from '../../../../theme/utils/formatNumber';
// sections
import Label from '../../../../theme/sections/label';
import Iconify from '../../../../theme/sections/iconify';
import { CustomAvatar } from '../../../../theme/sections/custom-avatar';
import MenuPopover from '../../../../theme/sections/menu-popover';
import ConfirmDialog from '../../../../theme/sections/confirm-dialog';

// ----------------------------------------------------------------------

BillingTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

export default function BillingTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
}) {
  const {
    transactionalId,
    receipient,
    isGroup,
    membersCount,
    message,
    messageLength,
    cost,
    createdAt,
  } = row;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>
          <Typography variant="subtitle2" noWrap>
            {transactionalId}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" noWrap>
            {receipient}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Label variant="soft" color={'secondary'}>
            {isGroup == true ? 'Group (' + membersCount + ')' : 'Individual'}
          </Label>
        </TableCell>
        <TableCell align="left">
          <Typography variant="caption">{message}</Typography>
        </TableCell>
        <TableCell align="center">
          <Label variant="soft" color={'warning'}>
            {messageLength}
          </Label>
        </TableCell>{' '}
        <TableCell align="center">
          <Label variant="soft" color={'success'}>
            {cost} Rs.
          </Label>
        </TableCell>
        <TableCell align="left">
          <Typography variant="caption">{fDateTime(createdAt)}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
}
