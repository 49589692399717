import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Billing Report
    billingReport: builder.mutation({
      query: (dateRange) => ({
        url: '/reports/billing',
        // url: '/message/log/date',
        method: 'POST',
        body: dateRange,
      }),
    }),

    // Get group view logs
    groupViewLog: builder.query({
      query: (id) => `/logs/group/messages/${id}`,
    }),

    // Get message logs
    groupLog: builder.query({
      query: () => '/logs/group',
    }),
  }),
});

export const { useGroupLogQuery, useGroupViewLogQuery, useBillingReportMutation } = reportApi;
