import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const waMemberApi = createApi({
  reducerPath: 'waMemberApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/wa/members',
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    //credentials: 'include', // This allows server to set cookies
  }),
  tagTypes: ['waMember'],
  endpoints: (builder) => ({
    // Get member by group id
    waMembers: builder.query({
      query: (id) => '/' + id,
      providesTags: ['waMember'],
    }),

    // add member
    addWaMember: builder.mutation({
      query: (member) => ({
        url: `/add/${member.group_id}`,
        method: 'POST',
        body: member,
      }),
      invalidatesTags: ['waMember'],
    }),

    // delete member
    deleteWaMember: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['waMember'],
    }),

    // update member
    updateWaMember: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['waMember'],
    }),

    // Get pending members
    waPendingMembers: builder.query({
      query: () => '/pending/list',
    }),
  }),
});

export const {
  useWaMembersQuery,
  useWaPendingMembersQuery,
  useAddWaMemberMutation,
  useUpdateWaMemberMutation,
  useDeleteWaMemberMutation,
} = waMemberApi;
