// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
// sections
import { CustomAvatar } from '../../../theme/sections/custom-avatar';
import { useSelector } from 'react-redux';
import Iconify from '../../../theme/sections/iconify';
import { IconButtonAnimate } from '../../../theme/sections/animate';
import { useCallback, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField, RHFUploadAvatar } from 'src/theme/sections/hook-form';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  // user details
  const { user } = useSelector((state) => state.auth);

  // dialogue handling
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //form
  const methods = useForm();

  const { setValue } = methods;

  //Avatar handling
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      if (file) {
        setValue('avatarUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  // username editing
  const [userBtnDisable, setUserBtnDisable] = useState(true);
  const [userEdit, setUserEdit] = useState(true);
  const handleSaveUsername = () => {
    setUserEdit(true);
    setUserBtnDisable(true);
  };

  // status editing
  const [statusBtnDisable, setStatusBtnDisable] = useState(true);
  const [statusEdit, setStatusEdit] = useState(true);
  const handleSaveStatus = () => {
    setStatusEdit(true);
    setStatusBtnDisable(true);
  };

  return (
    <StyledRoot>
      <CustomAvatar src="/" alt={user.name} name={user.name} />

      <Box sx={{ ml: 2, minWidth: 0 }}>
        <Typography variant="subtitle2" noWrap>
          {user.name}
        </Typography>

        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          {user.email}
        </Typography>
      </Box>

      <IconButtonAnimate size="small" color="success" onClick={handleClickOpen}>
        <Iconify fontSize="inherit" icon="material-symbols:edit-square-outline-sharp" />
      </IconButtonAnimate>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <FormProvider methods={methods}>
            <Stack container spacing={3}>
              <RHFUploadAvatar name="avatarUrl" onDrop={handleDrop} />
              <RHFTextField
                name="username"
                label="Username"
                disabled={userBtnDisable}
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {userEdit && (
                        <Button
                          variant="soft"
                          onClick={() => {
                            setUserEdit(false);
                            setUserBtnDisable(false);
                          }}
                          color="secondary"
                        >
                          <Iconify icon="material-symbols:edit" />
                        </Button>
                      )}

                      {!userEdit && (
                        <Button variant="soft" onClick={handleSaveUsername} color="success">
                          <Iconify icon="material-symbols:save" />
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <RHFTextField
                name="status"
                label="Status"
                type="text"
                disabled={statusBtnDisable}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {statusEdit && (
                        <Button
                          variant="soft"
                          onClick={() => {
                            setStatusEdit(false);
                            setStatusBtnDisable(false);
                          }}
                          color="secondary"
                        >
                          <Iconify icon="material-symbols:edit" />
                        </Button>
                      )}

                      {!statusEdit && (
                        <Button variant="soft" onClick={handleSaveStatus} color="success">
                          <Iconify icon="material-symbols:save" />
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </StyledRoot>
  );
}
