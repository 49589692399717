import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// components
import CreateQuickReplyForm from 'src/components/quickReply/CreateQuickReplyForm';
// sections
import { useSettingsContext } from '../../theme/sections/settings';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function CreateQuickReplyPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Create Quick Reply | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create Quick Reply for Text Message"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },

            {
              name: 'Create Quick Reply',
            },
          ]}
        />

        <CreateQuickReplyForm />
      </Container>
    </>
  );
}
