import PropTypes from 'prop-types';
import { useState } from 'react';
import { sentenceCase } from 'change-case';
// @mui
import {
  Stack,
  Button,
  TableRow,
  Checkbox,
  MenuItem,
  TableCell,
  IconButton,
  Chip,
  Avatar,
} from '@mui/material';
// utils
import { fDate } from '../../../../theme/utils/formatTime';
import { fCurrency } from '../../../../theme/utils/formatNumber';
// sections
import Label from '../../../../theme/sections/label';
import Image from '../../../../theme/sections/image';
import Iconify from '../../../../theme/sections/iconify';
import MenuPopover from '../../../../theme/sections/menu-popover';
import ConfirmDialog from '../../../../theme/sections/confirm-dialog';

// ----------------------------------------------------------------------

GroupTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function GroupTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  onEditRow,
  onViewRow,
}) {
  const { id, image, name, description, member_count, status, creation_time } = row;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}
        <TableCell align="center">
          <Chip avatar={<Avatar>ID#</Avatar>} label={id} color="primary" />
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              alt={name}
              src={image ? image : require('../../../../assets/profile/group_dp.png')}
            />
          </Stack>
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>
          <Chip
            variant="soft"
            label={member_count}
            color="info"
            onClick={onViewRow}
            sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
          />
        </TableCell>

        <TableCell>
          <Label variant="soft" color={status == 'Active' ? 'success' : 'warning'}>
            {status}
          </Label>
        </TableCell>

        <TableCell>{fDate(creation_time)}</TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'primary' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
