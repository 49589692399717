// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
import { BrowserRouter } from 'react-router-dom';
import { Navigate } from 'react-router';

import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// sections
import SnackbarProvider from './theme/sections/snackbar';
import { ThemeSettings, SettingsProvider } from './theme/sections/settings';
import { MotionLazyContainer } from './theme/sections/animate';
import ScrollToTop from './theme/sections/scroll-to-top';
import { SocketProvider } from './context/SocketContext';
import { useSelector } from 'react-redux';
import { store } from './redux/app/store';

// ----------------------------------------------------------------------
export default function App() {
  const { user } = useSelector((state) => state.auth);

  return (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <BrowserRouter>
            <ScrollToTop />
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeSettings>
                  <SnackbarProvider>
                    {user ? (
                      <SocketProvider store={store}>
                        <Router />
                      </SocketProvider>
                    ) : (
                      <Router />
                    )}
                  </SnackbarProvider>
                </ThemeSettings>
              </ThemeProvider>
            </MotionLazyContainer>
          </BrowserRouter>
        </SettingsProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
}
