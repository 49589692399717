// react
import { Navigate, useRoutes } from "react-router";
// redux
import { useSelector } from "react-redux";
//layout
import DashboardLayout from "./layouts/dashboard";
//auth
import LoginPage from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";
//local group
import CreateGroupPage from "./pages/group/local/CreateGroupPage";
import ListGroupPage from "./pages/group/local/ListGroupPage";
//local member
import AddMemberPage from "./pages/member/local/AddMemberPage";
import ListMemberPage from "./pages/member/local/ListMemberPage";
import SearchMemberPage from "./pages/member/local/SearchMemberPage";
//wa group
import CreateWaGroupPage from "./pages/group/wa/CreateWaGroupPage";
import ListWaGroupPage from "./pages/group/wa/ListWaGroupPage";
//wa member
import AddWaMemberPage from "./pages/member/wa/AddWaMemberPage";
import ListWaMemberPage from "./pages/member/wa/ListWaMemberPage";
import ListWaPendingMemberPage from "./pages/member/wa/ListWaPendingMemberPage";
import SearchWaMemberPage from "./pages/member/wa/SearchWaMemberPage";
// message
import SendMessagePage from "./pages/compose/SendMessagePage";
//dashboard
import AnalyticalDashboardPage from "./pages/dashboard/AnalyticalDashboardPage";
// poll
import CreatePollPage from "./pages/poll/CreatePollPage";
// reports
/// billing report
import BillingReportPage from "./pages/report/billing/BillingReportPage";
///logs
import InMessageLogPage from "./pages/report/logs/InMessageLogPage";
import OutMessageLogPage from "./pages/report/logs/OutMessageLogPage";
import GroupLogPage from "./pages/report/logs/GroupLogPage";
import GroupViewLogPage from "./pages/report/logs/GroupViewLogPage";
// support
// chat
import ChatPage from "./pages/chat/ChatPage";
// bot
import CreateAutoResponsePage from "./pages/bot/CreateAutoResponsePage";
import ListAutoResponsePage from "./pages/bot/ListAutoResponsePage";
//import AutoResponseFlow from './pages/bot/AutoResponseFlow';
// quick reply
import ListQuickReplyPage from "./pages/quickReply/ListQuickReplyPage";
import CreateQuickReplyPage from "./pages/quickReply/CreateQuickReplyPage";
// support
import SupportPage from "./pages/support/SupportPage";
import MonitorPage from "./pages/support/MonitorPage";
// forbidden
import Forbidden from "./pages/Forbidden";
import ListSubscribersPage from "./pages/member/ListSubscribersPage";
// ----------------------------------------------------------------------

export default function Router() {
  // checks if logged in
  const { user } = useSelector((state) => state.auth);

  const element = user ? <DashboardLayout /> : <Navigate to="/login" />;
  const loginElement = user ? <Navigate to="/dashboard" /> : <LoginPage />;
  //const registerElement = user ? <Navigate to="/dashboard" /> : <RegisterPage />;

  const requireAuth = (element, requiredPermissions = []) => {
    if (!user) {
      return <Navigate to="/login" />;
    }
    //console.log(requiredPermissions);
    // Check if user has the required permissions
    const hasPermissions = requiredPermissions.every((permission) =>
      user.permissions.some(
        (userPermission) => userPermission.guard_name === permission
      )
    );

    if (!hasPermissions) {
      return <Forbidden />;
    }

    return element;
  };

  const routes = useRoutes([
    {
      path: "/dashboard",
      element: element,
      children: [
        {
          index: true,
          element: requireAuth(<AnalyticalDashboardPage />, ["dashboard"]),
        },
      ],
    },
    {
      path: "/group",
      element: element,
      children: [
        // { index: true, element: <GroupPage /> },
        {
          path: "create",
          element: requireAuth(<CreateGroupPage />, ["group:create"]),
        },
        {
          path: "list",
          element: requireAuth(<ListGroupPage />, ["group:list"]),
        },
        { path: "list/member/:id", element: <ListMemberPage /> },
        { path: "list/subscribers", element: <ListSubscribersPage /> },
      ],
    },
    {
      path: "/wa/group",
      element: element,
      children: [
        {
          path: "create",
          element: requireAuth(<CreateWaGroupPage />, ["wagroup:create"]),
        },
        {
          path: "list",
          element: requireAuth(<ListWaGroupPage />, ["wagroup:list"]),
        },
        { path: "list/member/:id", element: <ListWaMemberPage /> },
      ],
    },
    {
      path: "/compose",
      element: element,
      children: [
        // { index: true, element: <GroupPage /> },
        {
          path: "send",
          element: requireAuth(<SendMessagePage />, ["compose:message"]),
        },
      ],
    },
    {
      path: "/member",
      element: element,
      children: [
        // { index: true, element: <GroupPage /> },
        {
          path: "add",
          element: requireAuth(<AddMemberPage />, ["member:add"]),
        },
        { path: "list", element: <ListMemberPage /> },
        {
          path: "search",
          element: requireAuth(<SearchMemberPage />, ["member:search"]),
        },
      ],
    },
    {
      path: "/wa/member",
      element: element,
      children: [
        // { index: true, element: <GroupPage /> },
        {
          path: "add",
          element: requireAuth(<AddWaMemberPage />, ["wamember:add"]),
        },
        { path: "list", element: <ListWaMemberPage /> },
        { path: "list/pending", element: <ListWaPendingMemberPage /> },
        {
          path: "search",
          element: requireAuth(<SearchWaMemberPage />, ["wamember:search"]),
        },
      ],
    },
    {
      path: "/poll",
      element: element,
      children: [
        {
          path: "create",
          element: requireAuth(<CreatePollPage />, ["poll:create"]),
        },
      ],
    },
    {
      path: "/report",
      element: element,
      children: [
        {
          path: "billing",
          element: requireAuth(<BillingReportPage />, ["billing"]),
        },
        {
          path: "logs/in-message",
          element: requireAuth(<InMessageLogPage />, ["logs:incoming"]),
        },
        {
          path: "logs/out-message",
          element: requireAuth(<OutMessageLogPage />, ["logs:outgoing"]),
        },
        {
          path: "logs/group",
          element: requireAuth(<GroupLogPage />, ["logs:groups"]),
        },
        {
          path: "logs/group/view/:id",
          element: requireAuth(<GroupViewLogPage />, ["logs:groups"]),
        },
      ],
    },
    {
      path: "/chat",
      element: element,
      children: [{ index: true, element: <ChatPage /> }],
    },
    {
      path: "/support",
      element: element,
      children: [
        {
          index: true,
          element: requireAuth(<SupportPage />, ["support:chat"]),
        },
      ],
    },
    {
      path: "/monitor/desk",
      element: element,
      children: [
        {
          index: true,
          element: requireAuth(<MonitorPage />, ["support:monitor"]),
        },
      ],
    },
    {
      path: "/quick",
      element: element,
      children: [
        {
          path: "reply/create",
          element: requireAuth(<CreateQuickReplyPage />, [
            "quick-reply:create",
          ]),
        },
        {
          path: "reply/list",
          element: requireAuth(<ListQuickReplyPage />, ["quick-reply:list"]),
        },
      ],
    },
    {
      path: "/bot",
      element: element,
      children: [
        // {
        //   path: 'auto-response',
        //   element: <AutoResponseFlow />,
        // },
        {
          path: "auto-response/create",
          element: requireAuth(<CreateAutoResponsePage />, [
            "auto-response:create",
          ]),
        },
        {
          path: "auto-response/list",
          element: requireAuth(<ListAutoResponsePage />, [
            "auto-response:list",
          ]),
        },
      ],
    },
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "login",
      element: loginElement,
    },
    // {
    //   path: 'register',
    //   element: registerElement,
    // },
  ]);

  return routes;
}
