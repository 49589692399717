import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const subscriberApi = createApi({
  reducerPath: 'subscriberApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Get subscribers list
    getSubscribers: builder.query({
      query: () => '/subscribers/list',
    }),
  }),
});

export const {
  useGroupLogQuery,
  useGroupViewLogQuery,
  useBillingReportMutation,
  useGetSubscribersQuery,
} = subscriberApi;
