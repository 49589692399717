import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
// sections
import { IconButtonAnimate } from '../../../theme/sections/animate';
import Iconify from '../../../theme/sections/iconify';
// redux
import { useDispatch } from 'react-redux';
import { logout, reset } from '../../../redux/features/auth/authSlice';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: '/',
  },
  {
    label: 'Settings',
    linkTo: '/',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(logout());
    dispatch(reset());
    navigate('/login');
  };
  return (
    <>
      <IconButtonAnimate
        onClick={handleClose}
        sx={{
          p: 0,
        }}
      >
        {/* <CustomAvatar src="/" alt={user.name} name={user.name} /> */}
        <Iconify icon="uiw:logout" sx={{ width: 20, height: 20 }} /> &nbsp; Logout
      </IconButtonAnimate>
    </>
  );
}
