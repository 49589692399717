import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Stack } from '@mui/material';
// sections
import { fNumber } from '../../../theme/utils/formatNumber';
import Iconify from '../../../theme/sections/iconify';

// ----------------------------------------------------------------------

AppWidget.propTypes = {
  sx: PropTypes.object,
  chart: PropTypes.object,
  color: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default function AppWidget({ title, total, icon, color, chart, sx, ...other }) {
  const theme = useTheme();

  const { series, options } = chart;

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        p: 3,
        borderRadius: 2,
        overflow: 'hidden',
        position: 'relative',
        color: 'common.white',
        bgcolor: `${color}`,
        ...sx,
      }}
      {...other}
    >
      <Box sx={{ ml: 3 }}>
        <Typography variant="h4"> {fNumber(total)}</Typography>

        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="caption" sx={{ opacity: 0.72 }}>
          Current Month
        </Typography>
      </Box>

      <Iconify
        icon={icon}
        sx={{
          width: 120,
          height: 100,
          opacity: 0.12,
          position: 'absolute',
          right: theme.spacing(1),
        }}
      />
    </Stack>
  );
}
