import { Helmet } from 'react-helmet-async';
// components
import Register from '../../components/auth/Register';
// ----------------------------------------------------------------------

export default function RegisterPage() {
  return (
    <>
      <Helmet>
        <title>Register | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Register />
    </>
  );
}
