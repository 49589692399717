import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/dashboard',
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // today count
    today: builder.query({
      query: () => ({
        url: '/today',
        method: 'GET',
      }),
    }),

    // today failed count
    todayFailed: builder.query({
      query: () => ({
        url: '/today/failed',
        method: 'GET',
      }),
    }),

    // month count
    month: builder.query({
      query: () => ({
        url: '/month',
        method: 'GET',
      }),
    }),

    // month graph
    monthGraph: builder.query({
      query: () => ({
        url: 'month/graph',
        method: 'GET',
      }),
    }),

    // subcribers count
    subscribers: builder.query({
      query: () => ({
        url: '/subscribers',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useTodayQuery,
  useTodayFailedQuery,
  useMonthQuery,
  useMonthGraphQuery,
  useSubscribersQuery,
} = dashboardApi;
