import PropTypes from 'prop-types';
import { useState } from 'react';
import { sentenceCase } from 'change-case';
// @mui
import {
  Stack,
  Button,
  TableRow,
  Checkbox,
  MenuItem,
  TableCell,
  IconButton,
  Chip,
  Avatar,
} from '@mui/material';
// utils
import { fDate } from '../../theme/utils/formatTime';
import { fCurrency } from '../../theme/utils/formatNumber';
// sections
import Label from '../../theme/sections/label';
import Image from '../../theme/sections/image';
import Iconify from '../../theme/sections/iconify';
import MenuPopover from '../../theme/sections/menu-popover';
import ConfirmDialog from '../../theme/sections/confirm-dialog';

// ----------------------------------------------------------------------

SubscriberTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function SubscriberTableRow({ row, selected, onViewRow }) {
  const { number, created_at } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell>{number}</TableCell>

      {/* <TableCell>
        <Chip
          variant="soft"
          label={members}
          color="info"
          onClick={onViewRow}
          sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
        />
      </TableCell> */}

      <TableCell>{fDate(created_at)}</TableCell>
    </TableRow>
  );
}
