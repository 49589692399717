import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// components
import CreateAutoResponseForm from 'src/components/bot/CreateAutoResponseForm';
// sections
import { useSettingsContext } from '../../theme/sections/settings';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function CreateAutoResponsePage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Create Auto Response | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create Auto-Reply to Text Message"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Bot',
              href: '#',
            },
            {
              name: 'Create Auto Response',
            },
          ]}
        />

        <CreateAutoResponseForm />
      </Container>
    </>
  );
}
