import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// components
import SearchMemberForm from 'src/components/member/wa/SearchMemberForm';
// sections
import { useSettingsContext } from '../../../theme/sections/settings';
import CustomBreadcrumbs from '../../../theme/sections/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function SearchWaMemberPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Search Whatsapp Member | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Search member details"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Whatsapp Member',
              href: '/member',
            },
            {
              name: 'Search',
            },
          ]}
        />

        <SearchMemberForm />
      </Container>
    </>
  );
}
