import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + '/users/';

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL + 'register', userData);

  // if (response.data) {
  //   localStorage.setItem('user', JSON.stringify(response.data));
  // }

  return response.data;
};

const login = async (userData) => {
  const response = await axios.post(API_URL + 'login/portal', userData);
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }
  return response.data;
};

// Logout user
const logout = () => {
  localStorage.removeItem('user');
};

const authService = {
  register,
  logout,
  login,
};

export default authService;
