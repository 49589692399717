import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useParams } from 'react-router';

// @mui
import {
  Card,
  Table,
  Button,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CardHeader,
} from '@mui/material';
// sections
import { useSnackbar } from '../../theme/sections/snackbar';
import { useSettingsContext } from '../../theme/sections/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../theme/sections/table';
import Iconify from '../../theme/sections/iconify';
import Scrollbar from '../../theme/sections/scrollbar';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';
import ConfirmDialog from '../../theme/sections/confirm-dialog';
// sections
import { useGetSubscribersQuery } from 'src/redux/services/subscriberApi';
import SubscriberTableRow from 'src/components/member/SubscriberTableRow';
import SubscriberTableToolbar from 'src/components/member/SubscriberTableToolbar';
// excel export
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { fDateTime, fDateYMD, fDate } from '../../theme/utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'number', label: 'Subscriber', align: 'left' },
  { id: 'created_at', label: 'Subscribed at', align: 'left' },
];

// ----------------------------------------------------------------------

export default function ListSubscribersPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'created_at',
  });

  const { themeStretch } = useSettingsContext();

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState('');

  // rtk
  const { data, isLoading, isSuccess } = useGetSubscribersQuery();

  useEffect(() => {
    if (isSuccess) {
      setTableData(data);
    }
    //console.log(data);
  }, [data, isSuccess]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const denseHeight = dense ? 60 : 80;

  const isFiltered = filterName !== '';

  const isNotFound = (!dataFiltered.length && !!filterName) || (isLoading && !dataFiltered.length);

  const handleFilterName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName('');
  };

  // excel export
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData, fileName) => {
    /* flatten objects */
    const rows = apiData.map((row) => ({
      Number: row.number,
      Subscribed_At: fDate(row.created_at),
    }));

    const ws = XLSX.utils.json_to_sheet(rows);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Helmet>
        <title>List Subscribers | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Subscribers"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Subscribers' }]}
        />

        <Card>
          <CardHeader
            action={
              <Button variant="contained" onClick={(e) => exportToCSV(dataFiltered, 'Subscribers')}>
                <Iconify icon="mdi:microsoft-excel" /> &nbsp; Export to excel
              </Button>
            }
            title="List of Subscriber"
          />

          <SubscriberTableToolbar
            filterName={filterName}
            onFilterName={handleFilterName}
            isFiltered={isFiltered}
            onResetFilter={handleResetFilter}
          />

          {/* <CardHeader title="Group Logs" /> */}
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary">
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  // onSelectAllRows={(checked) =>
                  //   onSelectAllRows(
                  //     checked,
                  //     tableData.map((row) => row.id)
                  //   )
                  // }
                />

                <TableBody>
                  {(isLoading ? [...Array(rowsPerPage)] : dataFiltered)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) =>
                      row ? (
                        <SubscriberTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                        />
                      ) : (
                        !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      )
                    )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filterName }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (data) => data.number.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return inputData;
}
