import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Stack, Typography, Box, CircularProgress } from '@mui/material';
// utils
import { fShortenNumber, fCurrency } from '../../../theme/utils/formatNumber';
// components
import Iconify from '../../../theme/sections/iconify';

// ----------------------------------------------------------------------

InvoiceAnalytic.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  price: PropTypes.number,
  total: PropTypes.number,
  percent: PropTypes.number,
};

export default function InvoiceAnalytic({ title, total, icon, color, percent, price }) {
  return (
    <Stack direction="row" sx={{ width: 1, minWidth: 180 }}>
      {/* <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
        <Iconify icon={icon} width={20} sx={{ color, position: 'absolute' }} />

        <CircularProgress
          variant="determinate"
          value={percent}
          size={36}
          thickness={4}
          sx={{ color, opacity: 0.48 }}
        />

        <CircularProgress
          variant="determinate"
          value={100}
          size={36}
          thickness={4}
          sx={{
            top: 0,
            left: 0,
            opacity: 0.48,
            position: 'absolute',
            color: (theme) => alpha(theme.palette.grey[500], 0.16),
          }}
        />
      </Stack> */}

      <Stack spacing={0.5} sx={{ ml: 2 }}>
        <Typography variant="h6">
          <Iconify icon={icon} width={28} sx={{ color, mt: 2, mr: 1 }} />
          {title}
        </Typography>

        <Typography variant="subtitle2">
          <Box component="span" sx={{ color: 'text.secondary.dark', typography: 'caption' }}>
            Monthly Count: &nbsp;
            <Typography sx={{ fontWeight: 'bold', fontSize: 14 }} color={color} variant="caption">
              {new Date().toLocaleString('default', { month: 'long' })}
            </Typography>
          </Box>
        </Typography>

        <Typography variant="subtitle2" sx={{ color, fontSize: 18 }}>
          {total ? fShortenNumber(total) : 0}
        </Typography>
      </Stack>
    </Stack>
  );
}
