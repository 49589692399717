import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
//@mui
import { Card, Container } from '@mui/material';
// components
import LogTableFilter from 'src/components/report/logs/LogTableFilter';
import LogTableSearch from 'src/components/report/logs/LogTableSearch';
// sections
import { useSettingsContext } from '../../../theme/sections/settings';
import CustomBreadcrumbs from '../../../theme/sections/custom-breadcrumbs';
//utils
import axios from '../../../theme/utils/axios';
import { fDateYMD } from 'src/theme/utils/formatTime';
import { useEffect, useState } from 'react';
//rtk
import { useOutMessagesQuery } from 'src/redux/services/messageApi';
// ----------------------------------------------------------------------
const SERVICE_OPTIONS = ['all', 'Sent', 'Pending', 'Failed'];

export default function OutMessageLogPage() {
  const { themeStretch } = useSettingsContext();

  const [out, setOut] = useState([]);

  const [filterName, setFilterName] = useState('');

  const [filterStatus, setFilterStatus] = useState('all');

  const [filterEndDate, setFilterEndDate] = useState(null);

  const [filterService, setFilterService] = useState('all');

  const [filterStartDate, setFilterStartDate] = useState(null);

  // user details
  const { user } = useSelector((state) => state.auth);
  const config = {
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  };

  // rtk
  const { data, isLoading, isSuccess } = useOutMessagesQuery();

  useEffect(() => {
    if (isSuccess) {
      setOut(data.logs);
    }
  }, [isSuccess]);

  const handleDateRange = async (start, end) => {
    const response = await axios.post(
      'message/logs/filter',
      {
        fromDate: fDateYMD(start),
        toDate: fDateYMD(end),
      },
      config
    );
    if (response.data.logs) {
      setOut(response.data.logs);
    }
  };

  const handleFilterName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterService = (event) => {
    setFilterService(event.target.value);
  };

  const handleFilterStatus = (event, newValue) => {
    setFilterStatus(newValue);
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterStatus('all');
    setFilterService('all');
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  const isFiltered =
    filterStatus !== 'all' ||
    filterName !== '' ||
    filterService !== 'all' ||
    (!!filterStartDate && !!filterEndDate);
  return (
    <>
      <Helmet>
        <title> Message Logs | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xlg'}>
        <CustomBreadcrumbs
          heading="Outgoing Message Logs"
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            {
              name: 'Message',
              href: '/message',
            },
            { name: 'Logs' },
          ]}
        />

        <Card>
          <LogTableFilter
            filterName={filterName}
            isFiltered={isFiltered}
            filterService={filterService}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            optionsService={SERVICE_OPTIONS}
            filterStartDate={filterStartDate}
            onResetFilter={handleResetFilter}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              handleDateRange(filterStartDate, newValue);
              setFilterEndDate(newValue);
            }}
          />

          {out.length > 0 && (
            <LogTableSearch
              tableData={out}
              filterName={filterName}
              filterService={filterService}
              filterStatus={filterStatus}
              filterEndDate={filterEndDate}
              filterStartDate={filterStartDate}
              SERVICE_OPTIONS={SERVICE_OPTIONS}
              isLoading={isLoading}
              handleFilterStatus={handleFilterStatus}
            />
          )}
        </Card>
      </Container>
    </>
  );
}
