import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const autoResponseApi = createApi({
  reducerPath: 'autoResponseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/auto/response',
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    //credentials: 'include', // This allows server to set cookies
  }),
  tagTypes: ['autoResponses'],
  endpoints: (builder) => ({
    // Get all autoResponses
    autoResponses: builder.query({
      query: () => '/',
      providesTags: ['autoResponses'],
    }),

    // create autoResponse
    createAutoResponse: builder.mutation({
      query: (auto_response) => ({
        url: '/',
        method: 'POST',
        body: auto_response,
      }),
      invalidatesTags: ['autoResponses'],
    }),

    // delete autoResponse
    deleteAutoResponse: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['autoResponses'],
    }),

    // update autoResponse
    updateAutoResponse: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['autoResponses'],
    }),
  }),
});

export const {
  useAutoResponsesQuery,
  useCreateAutoResponseMutation,
  useUpdateAutoResponseMutation,
  useDeleteAutoResponseMutation,
} = autoResponseApi;
