import PropTypes from 'prop-types';
// @mui
import { Stack, TableRow, TableCell } from '@mui/material';
// utils
import { fDate } from '../../../../theme/utils/formatTime';
// sections
import Label from '../../../../theme/sections/label';

// ----------------------------------------------------------------------

MemberTableRow.propTypes = {
  row: PropTypes.object,
};

export default function MemberTableRow({ row }) {
  const { groupName, name, number, status, createdAt } = row;

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Stack direction="row" spacing={2}>
            {groupName}
          </Stack>
        </TableCell>

        <TableCell>
          <Stack direction="row" spacing={2}>
            {name}
          </Stack>
        </TableCell>

        <TableCell>{number}</TableCell>

        <TableCell>
          <Label variant="soft" color="warning">
            {status}
          </Label>
        </TableCell>

        <TableCell>{fDate(createdAt)}</TableCell>
      </TableRow>
    </>
  );
}
