import { useSnackbar } from '../theme/sections/snackbar';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';

export const SocketContext = createContext();

export const SocketProvider = ({ children, store }) => {
  const isConnected = useRef(false);
  const { user } = useSelector((state) => state.auth);
  const [connected, setConnected] = useState(false);
  const [newMessage, setNewMessage] = useState([]);
  const [statusMessage, setStatusMessage] = useState([]);
  const [newChat, setNewChat] = useState([]);
  const [newChatList, setNewChatList] = useState({ list: [] });

  const socket = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isConnected.current && user.socketToken) {
      socket.current = io(`https://api.watify.net?access_token=Bearer ${user.socketToken}`, {
        transports: ['websocket'],
      });

      // socket.current = io(`http://127.0.0.1:3333?access_token=Bearer ${user.socketToken}`, {
      //   transports: ['websocket'],
      // });

      socket.current.on('connect', () => {
        isConnected.current = true;
        setConnected(true);
        //console.info('Successfully connected to socket');
      });

      socket.current.on('receiveMessage', (response) => {
        setNewMessage(response);
        //console.log('here is the response', response);
        enqueueSnackbar('Recieved New Message');
      });

      socket.current.on('statusMessage', (response) => {
        setStatusMessage(response);
        // console.log(response);
      });

      socket.current.on('pendingChat', (response) => {
        setNewChat(response);
        enqueueSnackbar('New chat added');
      });

      // socket.current.on('disconnect', () => {
      //   console.info(`Successfully disconnected`);
      //   isConnected.current = false;
      // });

      // socket.current.on('connection', (data) => {
      //   console.log('Connection Event: ', data);
      // });

      socket.current.on('chatList', (list) => {
        setNewChatList(list);
        //console.log(list);
        enqueueSnackbar('New chat has been added in your list.');
      });
    }

    // return () => {
    //   if (socket.current && socket.current.connected) {
    //     socket.current.disconnect();
    //   }
    // };
  }, []);

  const emit = (eventName, data, callback) => {
    if (socket.current && isConnected.current) {
      socket.current.emit(eventName, data, callback);
    } else {
      console.error('Socket is not connected.');
    }
  };

  return (
    <SocketContext.Provider
      value={{ emit, connected, newChatList, newMessage, statusMessage, newChat }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
