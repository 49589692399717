import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const supportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/chat',
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    // Get pending chats
    getPendingChat: builder.query({
      query: () => '/pending',
    }),

    // Get active chats
    getActiveChat: builder.query({
      query: () => '/active',
    }),

    // Get completed chats
    getCompletedChat: builder.query({
      query: () => '/completed',
    }),

    // Get agents
    getAgent: builder.query({
      query: () => '/agents',
    }),
  }),
});

export const {
  useGetActiveChatQuery,
  useGetCompletedChatQuery,
  useGetPendingChatQuery,
  useGetAgentQuery,
} = supportApi;
