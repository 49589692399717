import * as Yup from 'yup';
import { useEffect } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, Typography, Button, Divider, Box, Alert } from '@mui/material';
import { useSnackbar } from '../../theme/sections/snackbar';
import FormProvider, { RHFTextField } from '../../theme/sections/hook-form';
// @rtk
import { useCreateAutoResponseMutation } from 'src/redux/services/autoResponseApi';

// ----------------------------------------------------------------------

export default function CreateAutoResponseForm() {
  // form validation rules
  const validationSchema = Yup.object().shape({
    message: Yup.string().required('Message field is required.'),
    response: Yup.string().required('An auto response field is required.'),
  });

  // default values for useForm
  const defaultValues = {
    message: '',
    response: '',
  };
  // functions to build form returned by useForm() and useFieldArray() hooks
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { control, setValue, setError, handleSubmit, formState, clearErrors, reset } = methods;
  const { errors } = formState;

  // rtk
  const [createAutoResponse, result] = useCreateAutoResponseMutation();
  // form submission
  const onSubmit = (data) => {
    createAutoResponse(data);
  };

  // initialization
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (result.error) {
      enqueueSnackbar(
        result.error.data?.message ? result.error.data?.message : result.error.error,
        {
          variant: 'error',
        }
      );
      setError('afterSubmit', {
        message: result.error.data?.message ? result.error.data?.message : result.error.error,
      });
      setTimeout(() => clearErrors(), 10000);
    }
    if (result.isSuccess) {
      enqueueSnackbar('Auto response has been created successfully');
      reset();
    }
  }, [result]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={2}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

              <RHFTextField name="message" label="Type in your message here" />

              <RHFTextField
                name="response"
                label="Enter Auto response against the above message"
                multiline
                rows={3}
              />
            </Stack>

            <Divider light sx={{ mt: 3 }} />

            <Stack direction="row" spacing={1.5} sx={{ mt: 3 }} justifyContent="flex-end">
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                loading={result.isLoading}
              >
                Create Auto Response
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
