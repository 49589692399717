import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/message/chat',
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    //credentials: 'include', // This allows server to set cookies
  }),
  //tagTypes: ['Chat'],
  endpoints: (builder) => ({
    // Get all recipients
    recipients: builder.query({
      query: () => '/receipients',
      //providesTags: ['Chat'],
    }),

    // get recipient chat
    // recipientChat: builder.mutation({
    //   query: (number) => ({
    //     url: `history/${number}`,
    //   }),
    //   //invalidatesTags: ['Chat'],
    // }),

    // get recipient chat
    recipientChat: builder.query({
      query: (number) => `history/${number}`,
    }),
  }),
});
//useRecipientChatMutation
export const { useRecipientsQuery, useRecipientChatQuery } = chatApi;
