import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const messageApi = createApi({
  reducerPath: 'messageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/message',
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    //credentials: 'include', // This allows server to set cookies
  }),
  tagTypes: ['Message'],
  endpoints: (builder) => ({
    // Get message logs
    outMessages: builder.query({
      query: () => '/logs',
      providesTags: ['Message'],
    }),

    // Get message logs
    inMessages: builder.query({
      query: () => '/logs/in',
      providesTags: ['Message'],
    }),

    // send message
    sendMessage: builder.mutation({
      query: (message) => ({
        url: '/send/text',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: ['Message'],
    }),

    // send media message
    sendMediaMessage: builder.mutation({
      query: (message) => ({
        url: '/send/media',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: ['Message'],
    }),

    // send link message
    sendLinkMessage: builder.mutation({
      query: (message) => ({
        url: '/send/link',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: ['Message'],
    }),

    // send group message
    sendGroupMessage: builder.mutation({
      query: (message) => ({
        url: '/group/send/text',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: ['Message'],
    }),

    // send group media message
    sendGroupMediaMessage: builder.mutation({
      query: (message) => ({
        url: '/group/send/media',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: ['Message'],
    }),

    // send group link message
    sendGroupLinkMessage: builder.mutation({
      query: (message) => ({
        url: '/group/send/link',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: ['Message'],
    }),
  }),
});

export const {
  useOutMessagesQuery,
  useInMessagesQuery,
  useSendMessageMutation,
  useSendMediaMessageMutation,
  useSendLinkMessageMutation,
  useSendGroupMessageMutation,
  useSendGroupMediaMessageMutation,
  useSendGroupLinkMessageMutation,
} = messageApi;
