import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// components
import CreatePollForm from '../../components/poll/CreatPollForm';
// sections
import { useSettingsContext } from '../../theme/sections/settings';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function CreatePollPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Create a poll | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create a new poll"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Poll',
              href: '/poll',
            },
            {
              name: 'Create Poll',
            },
          ]}
        />

        <CreatePollForm />
      </Container>
    </>
  );
}
