import { Container, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
// sections
import { useSettingsContext } from 'src/theme/sections/settings';
import { MotionContainer, varBounce } from 'src/theme/sections/animate';
// assets
import { ForbiddenIllustration } from 'src/assets/illustrations';
// ----------------------------------------------------------------------

export default function Forbidden() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Access Denied | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container
        maxWidth={themeStretch ? false : 'lg'}
        component={MotionContainer}
        sx={{ textAlign: 'center' }}
      >
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </Container>
    </>
  );
}
