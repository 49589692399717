import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const quickReplyApi = createApi({
  reducerPath: 'quickReplyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/quick/reply',
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    //credentials: 'include', // This allows server to set cookies
  }),
  tagTypes: ['quickReplies'],
  endpoints: (builder) => ({
    // Get all autoResponses
    quickReplies: builder.query({
      query: () => '/',
      providesTags: ['quickReplies'],
    }),

    // create autoResponse
    createQuickReply: builder.mutation({
      query: (data) => ({
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['quickReplies'],
    }),

    // delete autoResponse
    deleteQuickReply: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['quickReplies'],
    }),

    // update autoResponse
    updateQuickReply: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['quickReplies'],
    }),
  }),
});

export const {
  useQuickRepliesQuery,
  useCreateQuickReplyMutation,
  useUpdateQuickReplyMutation,
  useDeleteQuickReplyMutation,
} = quickReplyApi;
