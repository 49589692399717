import {
  Avatar,
  Badge,
  Box,
  Button,
  Drawer,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  IconButton,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/theme/sections/iconify';
import Label from 'src/theme/sections/label/Label';
import Scrollbar from 'src/theme/sections/scrollbar';
import { useSettingsContext } from 'src/theme/sections/settings';
import { fToNow, wDateTime } from 'src/theme/utils/formatTime';

const PendingChats = ({ pendingList, userUrl, handleAddChat }) => {
  const { themeStretch } = useSettingsContext();

  const theme = useTheme();

  const isDesktopList = useResponsive('up', 'md');

  const [openList, setOpenList] = useState(false);

  const HEIGHT = 60;

  const NAV_WIDTH2 = 400;

  const StyledToggleListButton = styled((props) => <IconButton disableRipple {...props} />)(
    ({ theme }) => ({
      right: 0,
      zIndex: 9,
      width: 70,
      height: 70,
      position: 'absolute',
      top: theme.spacing(1),
      boxShadow: theme.customShadows.z8,
      backgroundColor: theme.palette.background.paper,
      border: `solid 1px ${theme.palette.divider}`,
      borderRight: 0,
      borderRadius: `12px 0 0 12px`,
      transition: theme.transitions.create('all'),
      '&:hover': {
        backgroundColor: theme.palette.background.neutral,
      },
    })
  );

  const onOpenList = () => {
    setOpenList(true);
  };

  const onCloseNav = () => {
    setOpenList(false);
  };

  useEffect(() => {
    if (!isDesktopList) {
      onCloseNav();
    }
  }, [isDesktopList]);

  const renderAssignContent = (
    <>
      <Button
        fullWidth
        color="inherit"
        sx={{
          height: 40,
          flexShrink: 0,
          borderRadius: 0,
          typography: 'overline',
          color: 'primary.main',
          justifyContent: 'space-between',
          p: (theme) => theme.spacing(1, 1.5, 1, 2.5),
          backgroundColor: 'rgb(244, 246, 248)',
        }}
        endIcon={<Iconify icon="material-symbols:close" />}
        onClick={() => setOpenList(!openList)}
      >
        Pending Chats
      </Button>
      <Box
        sx={{
          //   height: HEIGHT * 4,
          transition: (theme) =>
            theme.transitions.create('height', {
              duration: theme.transitions.duration.shorter,
            }),
        }}
      >
        <Scrollbar>
          {pendingList &&
            pendingList.list &&
            pendingList.list.map((list) => (
              <ListItemButton
                sx={{ px: 2, py: 1, borderBottom: '1px dotted lightgrey' }}
                key={list.message_log_id}
              >
                {/* height: HEIGHT, */}
                <ListItemAvatar>
                  {/* individual */}
                  <Avatar src={userUrl} sx={{ width: 55, height: 55 }} />
                </ListItemAvatar>
                {openList && (
                  <>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          {list.number}
                          <Iconify
                            icon="zondicons:add-outline"
                            color="primary.main"
                            width={20}
                            onClick={(e) => handleAddChat(e, list.id)}
                          />
                        </Box>
                      }
                      primaryTypographyProps={{ noWrap: true, variant: 'subtitle1' }}
                      secondary={
                        <>
                          <Typography variant="subtitle2" noWrap sx={{ mt: '1px' }}>
                            {list.message}
                          </Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="caption">{wDateTime(list.timestamp)}</Typography>
                            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                              {fToNow(list.timestamp)}
                            </Typography>
                          </Box>
                        </>
                      }
                    />
                  </>
                )}
              </ListItemButton>
            ))}
        </Scrollbar>
      </Box>
    </>
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <StyledToggleListButton
        onClick={() => setOpenList(!openList)}
        sx={{
          ...(openList &&
            isDesktopList && {
              right: NAV_WIDTH2,
            }),
        }}
      >
        {pendingList && pendingList.list.length > 0 ? (
          <Badge
            badgeContent={pendingList.list.length}
            color="error"
            children={<Iconify width={35} icon={'ri:chat-history-line'} color="primary.main" />}
          />
        ) : (
          <Iconify width={35} icon={'ri:chat-history-line'} color="primary.main" />
        )}
      </StyledToggleListButton>

      {isDesktopList ? (
        <Drawer
          open={openList}
          anchor="right"
          variant="persistent"
          PaperProps={{
            sx: {
              width: 1,
              position: 'static',
            },
          }}
          sx={{
            height: 1,
            width: NAV_WIDTH2,
            transition: theme.transitions.create('width'),
            ...(!openList && {
              width: 0,
            }),
          }}
        >
          {renderAssignContent}
        </Drawer>
      ) : (
        <Drawer
          anchor="right"
          open={openList}
          onClose={onCloseNav}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              width: NAV_WIDTH2,
            },
          }}
        >
          {renderAssignContent}
        </Drawer>
      )}
    </Box>
  );
};

export default PendingChats;
