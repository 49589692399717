import * as Yup from 'yup';
import { useState, useEffect, useCallback } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, Typography, Button, Divider, Box, Alert } from '@mui/material';
import { useSnackbar } from '../../theme/sections/snackbar';
import FormProvider, {
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
  RHFUpload,
} from '../../theme/sections/hook-form';
import { fData } from 'src/theme/utils/formatNumber';
// redux rtk
import {
  useMessagesQuery,
  useSendLinkMessageMutation,
  useSendMessageMutation,
  useSendMediaMessageMutation,
  useSendGroupLinkMessageMutation,
  useSendGroupMessageMutation,
  useSendGroupMediaMessageMutation,
} from '../../redux/services/messageApi';
import { useLocalGroupsQuery } from '../../redux/services/groupApi';
import { useWaGroupsQuery } from '../../redux/services/waGroupApi';
// ----------------------------------------------------------------------

export default function SendMessageForm() {
  const SEND_OPTION = [
    { label: 'Individual Message', value: 'individual' },
    { label: 'Group Message', value: 'group' },
    { label: 'Whatsapp Group Message', value: 'whatsapp' },
  ];
  const MESSAGE_OPTION = [
    { label: 'Text', value: 'text' },
    { label: 'Link Preview', value: 'link' },
    { label: 'Media Attachment', value: 'media' },
  ];

  //radio options handling
  const [selected, setSelected] = useState('individual');
  const changeHandler = (e) => {
    setSelected(e.target.value);
  };
  const [type, setType] = useState('text');
  const changeType = (e) => {
    setType(e.target.value);
  };

  // validation
  const validationSchema = Yup.object().shape({
    options: Yup.string(),
    number: Yup.string().when('options', {
      is: 'individual',
      then: Yup.string().required('Please enter contact number'),
    }),
    group_id: Yup.string().when('options', {
      is: 'group',
      then: Yup.string().required('Please select a group'),
    }),
    wagroup_id: Yup.string().when('options', {
      is: 'whatsapp',
      then: Yup.string().required('Please select a whatsapp group'),
    }),

    send_options: Yup.string(),
    message: Yup.string().when('send_options', {
      is: 'text',
      then: Yup.string().required('Please enter message'),
    }),
    link: Yup.string().when('send_options', {
      is: 'link',
      then: Yup.string().required('Link is required'),
    }),
    link_message: Yup.string().when('send_options', {
      is: 'link',
      then: Yup.string().required('Message is required'),
    }),
    media: Yup.string().when('send_options', {
      is: 'media',
      then: Yup.string().required('Media is required'),
    }),
  });

  //useform default values
  const defaultValues = {
    number: '',
    message: '',
    options: SEND_OPTION[0].value,
    send_options: MESSAGE_OPTION[0].value,
    link: '',
    link_message: '',
    caption: '',
    media: '',
  };
  // functions to build form returned by useForm() and useFieldArray() hooks
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const { control, setValue, setError, handleSubmit, formState, clearErrors, reset } = methods;
  const { errors } = formState;

  // media handling
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = '';
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        baseURL = reader.result;
        //console.log(baseURL);
        resolve(baseURL);
      };
    });
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      //console.log(file);
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('media', newFile, { shouldValidate: true });
        //convert file to base64
        getBase64(file)
          .then((result) => {
            file['base64'] = result;
            setValue('media', result);
            //console.log('File Is', file);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [setValue]
  );

  const handleRemoveFile = () => {
    setValue('media', null);
  };

  // redux rtk
  const { data: groups, isSuccess } = useLocalGroupsQuery();
  const { data: waGroups, isSuccess: isWaSuccess } = useWaGroupsQuery();
  const [sendMessage, result] = useSendMessageMutation();
  const [sendMediaMessage, mediaResult] = useSendMediaMessageMutation();
  const [sendLinkMessage, linkResult] = useSendLinkMessageMutation();
  const [sendGroupMessage, groupResult] = useSendGroupMessageMutation();
  const [sendGroupLinkMessage, groupLinkResult] = useSendGroupLinkMessageMutation();
  const [sendGroupMediaMessage, groupMediaResult] = useSendGroupMediaMessageMutation();

  // populate groups dropdown
  const [groupData, setGroupData] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setGroupData(groups);
    }
  }, [groups, isSuccess]);

  // populate whatsapp groups dropdown
  const [waGroupData, setWaGroupData] = useState([]);
  useEffect(() => {
    if (isWaSuccess) {
      setWaGroupData(waGroups);
      //console.log(waGroups);
    }
  }, [waGroups, isWaSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  //form submission
  const onSubmit = (data) => {
    if (data.options == 'individual') {
      switch (data.send_options) {
        case 'link':
          sendLinkMessage({
            number: data.number,
            message: data.link_message,
            link: data.link,
            isGroup: false,
          });
          break;
        case 'media':
          sendMediaMessage({
            number: data.number,
            message: data.caption,
            file: data.media,
            isGroup: false,
          });
          break;
        default:
          sendMessage({
            number: data.number,
            message: data.message,
            isGroup: false,
          });
      }
    } else if (data.options == 'whatsapp') {
      switch (data.send_options) {
        case 'link':
          sendLinkMessage({
            groupId: data.wagroup_id,
            message: data.link_message,
            link: data.link,
            isGroup: true,
          });
          break;
        case 'media':
          sendMediaMessage({
            number: data.wagroup_id,
            message: data.caption,
            file: data.media,
            isGroup: true,
          });
          break;
        default:
          sendMessage({ number: data.wagroup_id, message: data.message, isGroup: true });
      }
    } else {
      switch (data.send_options) {
        case 'link':
          sendGroupLinkMessage({
            groupId: data.group_id,
            message: data.link_message,
            link: data.link,
          });
          break;
        case 'media':
          sendGroupMediaMessage({
            groupId: data.group_id,
            message: data.caption,
            file: data.media,
          });
          break;
        default:
          sendGroupMessage({ groupId: data.group_id, message: data.message });
      }
    }
  };

  // after form submission
  useEffect(() => {
    const handleErrorMessage = (result, clearErrors) => {
      if (result.error) {
        const errorMessage = result.error.data?.message || result.error.error;
        setError('afterSubmit', { message: errorMessage });
        enqueueSnackbar(errorMessage, { variant: 'error' });
        setTimeout(() => clearErrors(), 10000);
      }
    };

    // Helper function for error handling
    const handleError = (result, clearErrors) => {
      if (result.error) {
        const errorMessage = result.error.data?.message || result.error.error;
        setError('afterSubmit', { message: errorMessage });
        enqueueSnackbar(errorMessage, { variant: 'error' });
        setTimeout(() => clearErrors(), 10000);
      }
    };

    // Example usage in your main code
    handleErrorMessage(result, clearErrors);
    handleErrorMessage(groupResult, clearErrors);
    handleErrorMessage(linkResult, clearErrors);
    handleErrorMessage(groupLinkResult, clearErrors);
    handleErrorMessage(mediaResult, clearErrors);
    handleErrorMessage(groupMediaResult, clearErrors);

    if (
      result.isSuccess ||
      linkResult.isSuccess ||
      groupResult.isSuccess ||
      groupLinkResult.isSuccess ||
      mediaResult.isSuccess ||
      groupMediaResult.isSuccess
    ) {
      enqueueSnackbar('Message has been sent successfully');
      reset(); //reset form
    }
  }, [result, linkResult, groupResult, groupLinkResult, mediaResult, groupMediaResult]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={2}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

              <RHFRadioGroup
                row
                spacing={4}
                name="options"
                options={SEND_OPTION}
                onClick={changeHandler}
              />
              {selected == 'individual' && <RHFTextField name="number" label="Contact Number" />}
              {selected == 'group' && (
                // <RHFAutocomplete
                //   name="groups"
                //   label="Select Groups"
                //   multiple
                //   freeSolo
                //   options={GROUPS_OPTION.map((option) => option)}
                // />
                <RHFSelect native name="group_id" label="Groups" placeholder="Groups">
                  <option value="" />
                  {groupData.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </RHFSelect>
              )}
              {selected == 'whatsapp' && (
                <RHFSelect
                  native
                  name="wagroup_id"
                  label="Whatsapp Groups"
                  placeholder="Whatsapp Groups"
                >
                  <option value="" />
                  {waGroupData
                    .filter((wagroup) => wagroup.status === 'Active')
                    .map((wagroup) => (
                      <option key={wagroup.id} value={wagroup.id}>
                        {wagroup.name}
                      </option>
                    ))}
                </RHFSelect>
              )}
              <RHFRadioGroup
                row
                spacing={4}
                name="send_options"
                options={MESSAGE_OPTION}
                onClick={(e) => changeType(e)}
              />
              {type == 'text' && (
                <RHFTextField name="message" label="Type your message here" multiline rows={3} />
              )}
              {type == 'link' && (
                <>
                  <RHFTextField name="link" label="Type the link to preview in the message." />
                  <RHFTextField
                    name="link_message"
                    label="Type your message here"
                    multiline
                    rows={3}
                  />
                </>
              )}
              {type == 'media' && (
                <>
                  <RHFTextField name="caption" label="Describe caption for this media (optional)" />
                  <RHFUpload
                    name="media"
                    maxSize={15000000}
                    onDrop={handleDrop}
                    onDelete={handleRemoveFile}
                    accept={{
                      'image/*': ['.png', '.jpeg', '.jpg', '.mp4'],
                    }}
                  />
                </>
              )}
            </Stack>

            <Divider light sx={{ mt: 3 }} />

            <Stack direction="row" spacing={1.5} sx={{ mt: 3 }} justifyContent="flex-end">
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                loading={
                  result.isLoading ||
                  linkResult.isLoading ||
                  groupLinkResult.isLoading ||
                  groupResult.isLoading ||
                  mediaResult.isLoading
                }
              >
                Send Message
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
