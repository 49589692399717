import PropTypes from 'prop-types';
import { useState } from 'react';
import { sentenceCase } from 'change-case';
// @mui
import {
  Stack,
  Button,
  TableRow,
  Checkbox,
  MenuItem,
  TableCell,
  IconButton,
  Link,
  Typography,
  Tooltip,
} from '@mui/material';
// utils
import { fDateTime } from '../../../theme/utils/formatTime';
// components
import Label from '../../../theme/sections/label';
import Image from '../../../theme/sections/image';
import Iconify from '../../../theme/sections/iconify';
import MenuPopover from '../../../theme/sections/menu-popover';
import ConfirmDialog from '../../../theme/sections/confirm-dialog';

// ----------------------------------------------------------------------

LogTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function LogTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  onEditRow,
  onViewRow,
}) {
  const { transactionalId, receipient, sender, message, type, status, createdAt, logs } = row;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>
          <Typography variant="subtitle2">{transactionalId}</Typography>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link
              noWrap
              color="inherit"
              variant="subtitle2"
              onClick={onViewRow}
              sx={{ cursor: 'pointer' }}
            >
              {sender}
            </Link>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography variant="caption">{message}</Typography>
        </TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={(type === 'media' && 'info') || (type === 'text' && 'error') || 'warning'}
            sx={{ textTransform: 'capitalize' }}
          >
            {type ? sentenceCase(type) : ''}
          </Label>
        </TableCell>

        <TableCell>
          <Tooltip title={logs ? logs : status} placement="right-start" arrow>
            <Label
              variant="filled"
              color={
                (status === 'Sent' && 'success') || (status === 'Failed' && 'error') || 'warning'
              }
              sx={{ textTransform: 'capitalize' }}
            >
              {status ? sentenceCase(status) : ''}
            </Label>
          </Tooltip>
        </TableCell>

        <TableCell>
          <Typography variant="caption">{fDateTime(createdAt)}</Typography>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
