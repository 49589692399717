import { Helmet } from 'react-helmet-async';
// components
import Chat from '../../components/chat/Chat';

// ----------------------------------------------------------------------

export default function ChatPage() {
  return (
    <>
      <Helmet>
        <title> Chat | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Chat />
    </>
  );
}
