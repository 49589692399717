import { useState, useEffect } from 'react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// sections
import Iconify from '../../theme/sections/iconify';
import FormProvider, { RHFTextField } from '../../theme/sections/hook-form';
import { useSnackbar } from '../../theme/sections/snackbar';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { register, reset } from '../../redux/features/auth/authSlice';
// ----------------------------------------------------------------------

export default function AuthRegisterForm() {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Full name required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    name: '',
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    dispatch(register(data));
  };

  useEffect(() => {
    if (isError) {
      setError('afterSubmit', {
        message: message,
      });
    }

    if (isSuccess) {
      enqueueSnackbar('Successfully Registered');
      dispatch(reset());
    }
  }, [user, isError, isSuccess, message, dispatch]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}
        <RHFTextField name="name" label="Full name" />
        {/* <RHFTextField name="lastName" label="Last name" /> */}
        {/* </Stack> */}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          sx={{
            bgcolor: 'text.primary',
            color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
            '&:hover': {
              bgcolor: 'text.primary',
              color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
            },
          }}
          loading={isLoading}
        >
          Create account
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
