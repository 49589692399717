import { Helmet } from 'react-helmet-async';
// components
import Login from '../../components/auth/Login';

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> Login | {process.env.REACT_APP_NAME} </title>
      </Helmet>
      <Login />
    </>
  );
}
