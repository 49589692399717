import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, Link } from '@mui/material';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import Logo from 'src/theme/sections/logo/Logo';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Logo
          sx={{
            mt: { xs: 1.5, md: 5 },
            ml: { xs: 2, md: 5, lg: 3 },
            width: 280,
          }}
        />
        <br />
        <Typography variant="h4">Sign in to portal</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">Please provide credentials to login to portal</Typography>

          {/* <Link component={RouterLink} to="/register" variant="subtitle2">
            Create an account
          </Link> */}
        </Stack>
      </Stack>

      {/* <Alert severity="info" sx={{ mb: 3 }}>
        Use email : <strong>demo@minimals.cc</strong> / password :<strong> demo1234</strong>
      </Alert> */}

      <AuthLoginForm />
    </LoginLayout>
  );
}
