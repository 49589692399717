import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// components
import SendMessageForm from '../../components/compose/SendMessageForm';
// sections
import { useSettingsContext } from '../../theme/sections/settings';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function SendMessagePage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Send Message | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Send a new Message"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Compose',
              href: '/compose',
            },
            {
              name: 'Message',
              href: '/message',
            },
            {
              name: 'Send Message',
            },
          ]}
        />

        <SendMessageForm />
      </Container>
    </>
  );
}
