import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const groupApi = createApi({
  reducerPath: 'groupApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/groups',
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    //credentials: 'include', // This allows server to set cookies
  }),
  tagTypes: ['Group'],
  endpoints: (builder) => ({
    // Get all groups
    groups: builder.query({
      query: () => '/details',
      providesTags: ['Group'],
    }),

    // Get all local groups
    localGroups: builder.query({
      query: () => '/',
      providesTags: ['Group'],
    }),

    // add group
    addGroup: builder.mutation({
      query: (group) => ({
        method: 'POST',
        body: group,
      }),
      invalidatesTags: ['Group'],
    }),

    // delete groups
    deleteGroup: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Group'],
    }),

    // update groups
    updateGroup: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Group'],
    }),
  }),
});

export const {
  useGroupsQuery,
  useLocalGroupsQuery,
  useAddGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} = groupApi;
