// sections
import SvgColor from '../../../theme/sections/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: icon('ic_analytics'),
  user: icon('ic_user'),
  group: icon('ic_groups'),
  group_add: icon('ic_group_add'),
  compose: icon('ic_compose'),
  poll: icon('ic_poll'),
  billing: icon('ic_invoice'),
  chat: icon('ic_chat'),
  support: icon('ic_support'),
  bot: icon('ic_bot'),
  logs: icon('ic_logs'),
  whatsapp: icon('ic_whatsapp'),
  quick_reply: icon('ic_quick_reply'),
  monitor: icon('ic_monitor'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    //subheader: 'Dashboard',
    items: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: ICONS.dashboard,
        guard: 'dashboard',
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    //subheader: 'Message Management',
    items: [
      {
        title: 'Local',
        path: '/group',
        icon: ICONS.group,
        guard: 'groups',
        children: [
          { title: 'Create Group', guard: 'group:create', path: '/group/create' },
          { title: 'List Groups', guard: 'group:list', path: '/group/list' },
          { title: 'Add Member', guard: 'member:add', path: '/member/add' },
          { title: 'Search Member', guard: 'member:search', path: '/member/search' },
        ],
      },
      {
        title: 'Whatsapp',
        path: '/wa/group',
        icon: ICONS.whatsapp,
        guard: 'whatsapp',
        children: [
          { title: 'Create Group', guard: 'wagroup:create', path: '/wa/group/create' },
          { title: 'List Groups', guard: 'wagroup:list', path: '/wa/group/list' },
          {
            title: 'Pending Members',
            guard: 'wapendingmember:list',
            path: '/wa/member/list/pending',
          },
          { title: 'Add Member', guard: 'wamember:add', path: '/wa/member/add' },
          { title: 'Search Member', guard: 'wamember:search', path: '/wa/member/search' },
        ],
      },
    ],
  },

  // MESSAGES
  // ----------------------------------------------------------------------
  {
    //subheader: 'Compose',
    items: [
      {
        title: 'Compose',
        path: '/message',
        icon: ICONS.compose,
        guard: 'compose',
        children: [{ title: 'Send Message', guard: 'compose:message', path: '/compose/send' }],
      },
    ],
  },
  {
    items: [
      {
        title: 'Subscribers',
        path: '/group/list/subscribers',
        icon: ICONS.group,
        guard: 'dashboard',
      },
    ],
  },

  // Templates
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'poll',
        path: '/poll',
        guard: 'poll',
        icon: ICONS.poll,
        children: [{ title: 'Create poll', guard: 'poll:create', path: '/poll/create' }],
      },
    ],
  },

  // Bot
  // ----------------------------------------------------------------------
  {
    //subheader: 'Bot',
    items: [
      {
        title: 'Auto Response',
        path: '/auto-response',
        icon: ICONS.bot,
        guard: 'auto-response',
        children: [
          {
            title: 'Auto Response Flow',
            guard: 'auto-response:create', //needs to be updated
            path: '/bot/auto-response',
          },
          {
            title: 'Create Auto Response',
            guard: 'auto-response:create',
            path: '/bot/auto-response/create',
          },
          {
            title: 'List Auto Response',
            guard: 'auto-response:list',
            path: '/bot/auto-response/list',
          },
        ],
      },
    ],
  },

  // Quick Reply
  // ----------------------------------------------------------------------

  {
    items: [
      {
        title: 'Quick Reply',
        path: '/quick/reply',
        icon: ICONS.quick_reply,
        guard: 'quick-reply',
        children: [
          { title: 'Create Quick Reply', guard: 'quick-reply:create', path: '/quick/reply/create' },
          { title: 'List Quick Replies', guard: 'quick-reply:list', path: '/quick/reply/list' },
        ],
      },
    ],
  },
  //Chat
  //----------------------------------------------------------------------
  {
    //subheader: 'Chat',
    items: [
      // {
      //   title: 'Chat',
      //   path: '/chat',
      //   icon: ICONS.chat,
      // },
      {
        title: 'Support',
        path: '/support',
        guard: 'support:chat',
        icon: ICONS.support,
      },
      {
        title: 'Monitor Desk',
        path: '/monitor/desk',
        guard: 'support:monitor',
        icon: ICONS.monitor,
      },
    ],
  },
  // Reports
  // ----------------------------------------------------------------------
  {
    //subheader: 'Reports',
    items: [
      {
        title: 'Billing Report',
        path: '/report/billing',
        guard: 'billing',
        icon: ICONS.billing,
      },
      {
        title: 'Message Logs',
        path: '/logs',
        icon: ICONS.logs,
        guard: 'logs',
        children: [
          { title: 'Incoming Logs', guard: 'logs:incoming', path: '/report/logs/in-message' },
          { title: 'Outgoing Logs', guard: 'logs:outgoing', path: '/report/logs/out-message' },
          { title: 'Group Logs', guard: 'logs:groups', path: '/report/logs/group' },
        ],
      },
    ],
  },
];

export default navConfig;
