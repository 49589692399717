import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, Typography, Button, Divider, Alert, TextField } from '@mui/material';
//sections
import { useSnackbar } from '../../../theme/sections/snackbar';
import Iconify from '../../../theme/sections/iconify';
import FormProvider, {
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from '../../../theme/sections/hook-form';
//xlxs excel reader
import * as xlsx from 'xlsx';
// redux rtk
import { useAddWaMemberMutation } from '../../../redux/services/waMemberApi';
import { useWaGroupsQuery } from '../../../redux/services/waGroupApi';

// ----------------------------------------------------------------------

export default function AddMemberForm() {
  // upload members options
  const UPLOAD_OPTION = [
    { label: 'Import Excel', value: 'excel' },
    { label: 'Add Manually', value: 'manual' },
  ];
  // selected upload option
  const [selected, setSelected] = useState('excel');
  const changeHandler = (e) => {
    setSelected(e.target.value);
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    group_id: Yup.string().required('Group is required'),
    from: Yup.string(),
    members: Yup.array().when('from', {
      is: 'manual',
      then: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Name is required'),
          number: Yup.number()
            .test('len', 'Maximum 13 digits', (val) => val.toString().length <= 13)
            .test('len', 'Minimum 10 digits', (val) => val.toString().length >= 10)
            .transform((value) =>
              isNaN(value) || value === null || value === undefined ? 0 : value
            )
            .required(),
        })
      ),
    }),
  });

  // default values for useForm
  const defaultValues = {
    group_id: '',
    from: UPLOAD_OPTION[0].value,
    members: [
      {
        name: '',
        number: '',
      },
    ],
    members_excel: [],
  };
  // functions to build form returned by useForm() and useFieldArray() hooks
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const { control, setValue, setError, handleSubmit, formState, clearErrors, reset } = methods;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    name: 'members',
    control,
  });

  //add dynamic fields for member
  const handleAdd = () => {
    append({
      name: '',
      number: '',
    });
  };
  //remove dynamic field for member
  const handleRemove = (i) => {
    remove(i);
  };
  //set value for dynamic field for member
  const handleChange = useCallback(
    (e, i, name) => {
      setValue(`members[${i}].` + name, e.target.value);
    },
    [setValue]
  );

  // file handling for import
  const [fileDetails, setFileDetails] = useState('');
  const readUploadFile = (e) => {
    //set selected file name in details
    setFileDetails(e.target.files[0].name);
    //excel to json conversion
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const range = xlsx.utils.decode_range(worksheet['!ref']);
        const number_of_rows = range.e.r;

        // checks if number of rows exceeds limit of 1000 rows
        if (number_of_rows > process.env.REACT_APP_LIMIT) {
          enqueueSnackbar('Number of members exceeded.', { variant: 'error' });
          setError('afterSubmit', {
            message:
              'Number of members exceeded  in excel sheet. Only ' +
              process.env.REACT_APP_LIMIT +
              ' members can be imported at a time.',
          });
        }

        const json = xlsx.utils.sheet_to_json(worksheet);
        setValue('members_excel', json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  // redux
  const { enqueueSnackbar } = useSnackbar();
  const [groupData, setGroupData] = useState([]);
  const { data: groups, isSuccess } = useWaGroupsQuery();
  const [addMember, result] = useAddWaMemberMutation();

  useEffect(() => {
    if (isSuccess) {
      setGroupData(groups);
    }
  }, [groups, isSuccess]);

  // form submission
  const onSubmit = (data) => {
    //console.log(data);
    //add members to group
    if (data.from == 'excel') {
      if (data.members_excel.length == 0) {
        enqueueSnackbar('No members data provided in excel sheet.', { variant: 'error' });
        setError('afterSubmit', {
          message: 'Please import excel sheet. Members are mandatory to create group',
        });
      } else {
        addMember({
          group_id: data.group_id,
          members: data.members_excel,
        });
      }
    } else {
      addMember({
        group_id: data.group_id,
        members: data.members,
      });
    }
  };

  useEffect(() => {
    if (result.error) {
      enqueueSnackbar(
        result.error.data?.message ? result.error.data?.message : result.error.error,
        { variant: 'error' }
      );
      setError('afterSubmit', {
        message: result.error.data?.message ? result.error.data?.message : result.error.error,
      });
      setTimeout(() => clearErrors(), 10000);
    }
    if (result.isSuccess) {
      enqueueSnackbar('Group Members has been added successfully');
      setFileDetails('');
      reset();
    }
  }, [result]);

  // download sample
  const onDownload = () => {
    const link = document.createElement('a');

    link.download = `sample.xlsx`;
    link.href = '/sample.xlsx';
    console.log(link.href);
    link.click();
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
              <RHFSelect native name="group_id" label="groups" placeholder="Groups">
                <option value="" />
                {groupData.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </RHFSelect>
              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Group Members
                </Typography>

                <RHFRadioGroup
                  row
                  spacing={4}
                  name="from"
                  options={UPLOAD_OPTION}
                  onClick={(e) => changeHandler(e)}
                />
                <Grid item xs={12}>
                  {selected === 'excel' && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Button
                        onClick={onDownload}
                        sx={{ mr: 2 }}
                        color="inherit"
                        variant="contained"
                      >
                        Download Sample
                      </Button>
                      <Button variant="contained" component="label">
                        <Iconify icon="mdi:microsoft-excel" /> &nbsp; Upload Excel
                        <input
                          hidden
                          accept=".xls,.xlsx"
                          multiple
                          type="file"
                          onChange={readUploadFile}
                        />
                      </Button>
                      {fileDetails && (
                        <Typography
                          variant="body"
                          sx={{ color: 'success.main', my: 2, mx: 2, fontWeight: 'bold' }}
                        >
                          {fileDetails}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {selected === 'manual' && (
                    <Grid item container spacing={2}>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Button
                          startIcon={<Iconify icon="eva:plus-fill" />}
                          onClick={handleAdd}
                          variant="contained"
                        >
                          Add Member
                        </Button>
                      </Grid>
                      {fields.map((item, i) => (
                        <Grid item key={item.id} container spacing={2}>
                          <Grid item lg={5} md={5} sm={12} xs={12}>
                            <Controller
                              name={`members[${i}].name`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  label={i + 1 + '. Member Name'}
                                  {...field}
                                  fullWidth
                                  error={!!error}
                                  helperText={errors.members?.[i]?.name?.message}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item lg={5} md={5} sm={12} xs={12}>
                            <Controller
                              name={`members[${i}].number`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  label={i + 1 + '. Member number'}
                                  {...field}
                                  fullWidth
                                  error={!!error}
                                  helperText={errors.members?.[i]?.number?.message}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item lg={2} md={2} sm={12} xs={12}>
                            <Button
                              onClick={() => handleRemove(i)}
                              startIcon={<Iconify icon="eva:trash-2-outline" />}
                              variant="contained"
                              color="error"
                              sx={{ my: 1 }}
                            >
                              Remove
                            </Button>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </Stack>

            <Divider light sx={{ mt: 3 }} />

            <Stack direction="row" spacing={1.5} sx={{ mt: 3 }} justifyContent="flex-end">
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                loading={result.isLoading}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
