import { Helmet } from 'react-helmet-async';
import { useEffect, useRef, useState } from 'react';
import sumBy from 'lodash/sumBy';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  Typography,
  CardHeader,
  CardActions,
  CardContent,
  LinearProgress,
  Paper,
} from '@mui/material';
// utils
import { fDateTime, fDateYMD, fDate } from '../../../theme/utils/formatTime';
// sections
import Label from '../../../theme/sections/label';
import Iconify from '../../../theme/sections/iconify';
import Scrollbar from '../../../theme/sections/scrollbar';
import ConfirmDialog from '../../../theme/sections/confirm-dialog';
import CustomBreadcrumbs from '../../../theme/sections/custom-breadcrumbs';
import { useSettingsContext } from '../../../theme/sections/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../theme/sections/table';
// components
import BillingAnalytic from '../../../components/report/billing/BillingAnalytic';
import { BillingTableRow, BillingTableToolbar } from '../../../components/report/billing/list';
// redux rtk
import { useBillingReportMutation } from '../../../redux/services/reportApi';
// excel export
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'transactionalId', label: 'Transactional Id', align: 'left' },
  { id: 'receipient', label: 'Receipient', align: 'left' },
  { id: 'isGroup', label: 'Type', align: 'left' },
  { id: 'message', label: 'Message', align: 'left', width: 600 },
  { id: 'messageLength', label: 'Length', align: 'center' },
  { id: 'cost', label: 'Cost', align: 'center' },
  { id: 'createdAt', label: 'Created At', align: 'left' },
];

// ----------------------------------------------------------------------

export default function BillingReportPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'createDate' });

  const [tableData, setTableData] = useState([]);

  const [filterEndDate, setFilterEndDate] = useState(null);

  const [filterStartDate, setFilterStartDate] = useState(null);

  const dataFiltered = tableData;
  const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const denseHeight = dense ? 56 : 76;

  const isFiltered = !!filterStartDate && !!filterEndDate;

  const isNotFound =
    (!dataFiltered.length && !!filterEndDate) || (!dataFiltered.length && !!filterStartDate);

  const handleResetFilter = () => {
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  // rtk
  const [billingReport, result] = useBillingReportMutation();

  // default date range
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  useEffect(() => {
    setFilterEndDate(today);
    setFilterStartDate(yesterday);

    billingReport({
      fromDate: fDateYMD(yesterday),
      toDate: fDateYMD(today),
    });
  }, []);

  useEffect(() => {
    if (result.isSuccess) {
      setTableData(result.data.logs);
    }
  }, [result]);

  const handleDateFilter = () => {
    billingReport({
      fromDate: fDateYMD(filterStartDate),
      toDate: fDateYMD(filterEndDate),
    });
  };

  // excel export
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData, fileName) => {
    /* flatten objects */
    const rows = apiData.map((row) => ({
      Transactional_Id: row.transactionalId,
      Recipient: row.receipient,
      Type: row.isGroup == 1 ? 'Group (' + row.membersCount + ')' : 'individual',
      Message: row.message,
      Message_Length: row.messageLength,
      Cost: row.cost + ' Rs.',
      Timestamp: fDateTime(row.createdAt),
    }));

    const ws = XLSX.utils.json_to_sheet(rows);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Helmet>
        <title> Billing: List | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xlg'}>
        <CustomBreadcrumbs
          heading="Billing List"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Billng',
              href: 'billing',
            },
            {
              name: 'List',
            },
          ]}
        />

        <Card sx={{ mb: 5 }}>
          <CardHeader title="Filter by date range" />

          <BillingTableToolbar
            isFiltered={isFiltered}
            onResetFilter={handleResetFilter}
            onDateFilter={handleDateFilter}
            filterEndDate={filterEndDate}
            filterStartDate={filterStartDate}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(newValue);
            }}
          />
        </Card>

        <Card sx={{ mb: 5 }}>
          <Scrollbar>
            {result.isLoading && (
              <Paper sx={{ p: '50px' }}>
                <LinearProgress color="success" />
              </Paper>
            )}
            {result.isSuccess && (
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
                sx={{ py: 2 }}
              >
                <BillingAnalytic
                  title="Total"
                  total={result.data.totalMessages}
                  percent={100}
                  price={result.data.totalCost}
                  icon="tabler:message-2-dollar"
                  color={theme.palette.info.main}
                  isLoading={result.isLoading}
                />

                <BillingAnalytic
                  title="Individual"
                  total={result.data.totalIndividualMessages}
                  percent={100}
                  price={result.data.totalIndividualCost}
                  icon="mdi:comment-user-outline"
                  color={theme.palette.success.main}
                />

                <BillingAnalytic
                  title="Group"
                  total={result.data.totalGroupMessages}
                  extra={result.data.totalMembers}
                  percent={100}
                  price={result.data.totalGroupCost}
                  icon="healthicons:group-discussion-meetingx3"
                  color={theme.palette.warning.main}
                />
              </Stack>
            )}
          </Scrollbar>
        </Card>

        <Card>
          <CardHeader
            action={
              <Button variant="contained" onClick={(e) => exportToCSV(dataFiltered, 'Billing')}>
                <Iconify icon="mdi:microsoft-excel" /> &nbsp; Export to excel
              </Button>
            }
            title={' From: ' + fDateYMD(filterStartDate) + ' to ' + fDateYMD(filterEndDate)}
            subheader="Filtered records"
          />
          <CardContent>
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }} id="my-table-id">
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    onSort={onSort}
                  />

                  <TableBody>
                    {dataFiltered
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => (
                        <BillingTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                        />
                      ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                    />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
