import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Stack, Typography, Box, CircularProgress } from '@mui/material';
// utils
import { fShortenNumber, fCurrency } from '../../../theme/utils/formatNumber';
// sections
import Iconify from '../../../theme/sections/iconify';

// ----------------------------------------------------------------------

BillingAnalytic.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  price: PropTypes.number,
  total: PropTypes.number,
  percent: PropTypes.number,
  extra: PropTypes.string,
};

export default function BillingAnalytic({ title, total, icon, color, percent, price, extra }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: 1, minWidth: 200 }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
        <Iconify icon={icon} width={40} sx={{ color, position: 'absolute' }} />

        <CircularProgress
          variant="determinate"
          value={percent}
          size={60}
          thickness={4}
          sx={{ color, opacity: 0.48 }}
        />

        <CircularProgress
          variant="determinate"
          value={100}
          size={60}
          thickness={4}
          sx={{
            top: 0,
            left: 0,
            opacity: 0.48,
            position: 'absolute',
            color: (theme) => alpha(theme.palette.grey[500], 0.16),
          }}
        />
      </Stack>

      <Stack spacing={0.5} sx={{ ml: 2 }}>
        <Typography variant="h6">{title}</Typography>
        {extra && (
          <Typography variant="subtitle">
            {extra} {extra.toString() <= 1 ? 'Recipient' : 'Recipients'}
          </Typography>
        )}

        <Typography variant="subtitle">
          {total == 0 ? 0 : fShortenNumber(total)} &nbsp;
          <Box component="span" sx={{ color: 'text.secondary', typography: 'subtitle' }}>
            {total.toString() <= 1 ? 'Message' : 'Messages'}
          </Box>
        </Typography>

        <Typography variant="subtitle" sx={{ color }}>
          {price == 0 ? 0 : fCurrency(price)} Rs.
        </Typography>
      </Stack>
    </Stack>
  );
}
