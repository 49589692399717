import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Card, Typography, Stack, Avatar } from '@mui/material';
// utils
import { fData } from '../../../theme/utils/formatNumber';

// ----------------------------------------------------------------------

Statistics.propTypes = {
  data: PropTypes.array,
  chart: PropTypes.object,
  total: PropTypes.number,
};

export default function Statistics({ data, total, chart, ...other }) {
  const theme = useTheme();

  const { colors, series, options } = chart;

  return (
    <Stack spacing={3} sx={{ p: 1 }}>
      {data.map((category) => (
        <Stack key={category.name} spacing={1} direction="row" alignItems="center">
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: category.color,
              color: 'white',
              width: 30,
              height: 30,
              borderRadius: 1,
            }}
          >
            {category.icon}
          </Avatar>

          <Stack spacing={3} flexGrow={1}>
            <Typography variant="body"> {category.name} </Typography>
            {/* <Typography variant="caption" sx={{ color: 'text.disabled' }}>
              {category.filesCount} files
            </Typography> */}
          </Stack>

          <Typography variant="h6" color={category.color}>
            {category.value}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
