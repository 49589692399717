import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// mui
import {
  Avatar,
  Badge,
  Box,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  CardHeader,
} from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
// sections
import { useSettingsContext } from '../../theme/sections/settings';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';
import Iconify from 'src/theme/sections/iconify';
import Label from 'src/theme/sections/label';
import { fDateTime, fToNow } from 'src/theme/utils/formatTime';

//rtk
import {
  useGetActiveChatQuery,
  useGetAgentQuery,
  useGetCompletedChatQuery,
  useGetPendingChatQuery,
} from 'src/redux/services/supportApi';
// ----------------------------------------------------------------------

export default function MonitorPage() {
  const { themeStretch } = useSettingsContext();
  const theme = useTheme();
  const userUrl = `${process.env.PUBLIC_URL}/assets/profile/user_dp.png`;
  const [currentTab, setCurrentTab] = useState('pending');
  const [open, setOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [pendingChats, setPendingChats] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [activeChats, setActiveChats] = useState([]);
  const [completedChats, setCompletedChats] = useState([]);
  const [completedCount, setCompletedCount] = useState(0);
  const [agents, setAgents] = useState([]);
  const [agentCount, setAgentCount] = useState(0);

  const TABS = [
    { value: 'pending', color: 'warning', count: pendingCount },
    { value: 'active', color: 'success', count: activeCount },
    { value: 'completed', color: 'info', count: completedCount },
    { value: 'agents', color: 'secondary', count: agentCount },
  ];

  const handleChangeTab = (event, val) => {
    setCurrentTab(val);
  };

  const handleClickOpen = (chat) => {
    setSelectedChat(chat);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChat(null);
  };

  //rtk
  const {
    data: pendingChat,
    isSuccess: pendingIsSuccess,
    isLoading: pendingLoading,
  } = useGetPendingChatQuery('data', {
    pollingInterval: 10000,
  });

  const {
    data: agentData,
    isSuccess: agentIsSuccess,
    isLoading: agentLoading,
  } = useGetAgentQuery('data', {
    pollingInterval: 10000,
  });

  const {
    data: activeChat,
    isSuccess: activeIsSuccess,
    isLoading: activeLoading,
  } = useGetActiveChatQuery('data', {
    pollingInterval: 10000,
  });

  const {
    data: completedChat,
    isSuccess: completedIsSuccess,
    isLoading: completedLoading,
  } = useGetCompletedChatQuery('data', {
    pollingInterval: 10000,
  });

  useEffect(() => {
    if (pendingIsSuccess) {
      setPendingChats(pendingChat);
      setPendingCount(pendingChat.length);
    }
  }, [pendingChat, pendingIsSuccess]);

  useEffect(() => {
    if (activeIsSuccess) {
      setActiveChats(activeChat);
      setActiveCount(activeChat.length);
    }
  }, [activeChat, activeIsSuccess]);

  useEffect(() => {
    if (completedIsSuccess) {
      setCompletedChats(completedChat);
      setCompletedCount(completedChat.length);
    }
  }, [completedChat, completedIsSuccess]);

  useEffect(() => {
    if (agentIsSuccess) {
      setAgents(agentData);
      setAgentCount(agentData.length);
    }
  }, [agentIsSuccess, agentData]);

  return (
    <>
      <Helmet>
        <title> Chat Monitoring Desk | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Chat Monitoring Desk"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },

            {
              name: 'Chat',
            },
            {
              name: 'Monitoring Desk',
            },
          ]}
        />

        <Box>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.value}
                iconPosition="end"
                icon={<Label color={tab.color}>{tab.count}</Label>}
              />
            ))}
          </Tabs>
        </Box>

        {currentTab === 'pending' && (
          <>
            <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
              Pending Chats
            </Typography>
            <Box
              gap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
                //lg: 'repeat(4, 1fr)',
              }}
            >
              {pendingIsSuccess && (
                <>
                  {pendingChats.length > 0 ? (
                    pendingChats.map((pending) => (
                      <Card key={pending.id}>
                        <Typography
                          variant="caption"
                          color="text.disabled"
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Iconify icon="mdi:history" width={18} height={18} sx={{ mr: 0.5 }} />
                          {fToNow(pending.timestamp)}
                        </Typography>
                        <Stack sx={{ p: 3, pb: 2 }}>
                          <ListItemButton disableGutters>
                            <ListItemAvatar>
                              <Avatar
                                src={userUrl}
                                variant="rounded"
                                sx={{ width: 48, height: 48, mb: 2 }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              sx={{ mb: 1 }}
                              primary={pending.number}
                              secondary={`Started Date: ${fDateTime(pending.timestamp)}`}
                              primaryTypographyProps={{
                                typography: 'subtitle1',
                              }}
                              secondaryTypographyProps={{
                                //mt: 1,
                                component: 'span',
                                typography: 'caption',
                                color: 'text.disabled',
                              }}
                            />
                          </ListItemButton>

                          <Stack spacing={0.5}>
                            <Typography variant="body2" noWrap sx={{ fontStyle: 'italic' }}>
                              <Iconify width={16} icon="uiw:message" sx={{ mr: 0.3 }} />
                              {pending.message}
                            </Typography>
                          </Stack>
                        </Stack>

                        <Divider sx={{ borderStyle: 'dashed' }} />

                        <Box
                          rowGap={1}
                          display="grid"
                          gridTemplateColumns="repeat(2, 1fr)"
                          sx={{ p: 2 }}
                        >
                          <div>
                            <Label color="warning">Pending</Label>
                          </div>
                          <Label variant="outlined">Not Assigned</Label>
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Typography variant="h6" sx={{ p: 3 }}>
                      No pending chats available
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </>
        )}

        {currentTab === 'active' && (
          <>
            <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
              Active Chats
            </Typography>
            <Box
              gap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
                //lg: 'repeat(4, 1fr)',
              }}
            >
              {activeIsSuccess && (
                <>
                  {activeChats.length > 0 ? (
                    activeChats.map((active) => (
                      <Card key={active.id}>
                        <Typography
                          variant="caption"
                          color="text.disabled"
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Iconify icon="mdi:history" width={18} height={18} sx={{ mr: 0.5 }} />{' '}
                          {fToNow(active.timestamp)}
                        </Typography>

                        <Stack sx={{ p: 3, pb: 2 }}>
                          <ListItemButton disableGutters>
                            <ListItemAvatar>
                              <Avatar
                                src={userUrl}
                                variant="rounded"
                                sx={{ width: 48, height: 48, mb: 2 }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              sx={{ mb: 1 }}
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {active.number}
                                  <Iconify
                                    icon="material-symbols:info"
                                    width={20}
                                    height={20}
                                    sx={{ ml: 0.5 }}
                                    onClick={() => handleClickOpen(active)}
                                  />
                                </Typography>
                              }
                              secondary={`Started Date: ${fDateTime(active.timestamp)}`}
                              secondaryTypographyProps={{
                                component: 'span',
                                typography: 'caption',
                                color: 'text.disabled',
                              }}
                            />
                          </ListItemButton>

                          <Stack spacing={2}>
                            <Typography variant="body2" noWrap sx={{ fontStyle: 'italic' }}>
                              <Iconify width={16} icon="uiw:message" sx={{ mr: 0.3 }} />
                              {active.message}
                            </Typography>

                            <Stack spacing={0.5} direction="row" alignItems="center">
                              <Typography variant="caption" color="text.disabled">
                                Assigned To:
                              </Typography>
                              <Label variant="outlined" color="primary">
                                {active.assignedUser?.name}
                              </Label>
                            </Stack>
                          </Stack>
                        </Stack>

                        <Divider sx={{ borderStyle: 'dashed' }} />

                        <Box
                          rowGap={1}
                          display="grid"
                          gridTemplateColumns="repeat(2, 1fr)"
                          sx={{ p: 2 }}
                        >
                          <div>
                            <Label color="primary">Active</Label>
                          </div>

                          <Typography variant="subtitle2">
                            {active.chatAssignLog && active.chatAssignLog.length > 0
                              ? active.chatAssignLog[active.chatAssignLog.length - 1].status
                              : 'No Status'}
                          </Typography>
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Typography variant="h6" sx={{ p: 3 }}>
                      No active chats available
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </>
        )}

        {currentTab === 'completed' && (
          <>
            <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
              Completed Chats
            </Typography>
            <Box
              gap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
                //lg: 'repeat(4, 1fr)',
              }}
            >
              {completedIsSuccess && (
                <>
                  {completedChats.length > 0 ? (
                    completedChats.map((completed) => (
                      <Card key={completed.id}>
                        <Iconify
                          icon="material-symbols:info"
                          width={20}
                          height={20}
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={() => handleClickOpen(completed)}
                        />

                        <Stack sx={{ p: 3, pb: 2 }}>
                          <ListItemButton disableGutters>
                            <ListItemAvatar>
                              <Avatar
                                src={userUrl}
                                variant="rounded"
                                sx={{ width: 48, height: 48, mb: 2 }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              sx={{ mb: 1 }}
                              primary={completed.number}
                              secondary={`Started Date: ${fDateTime(completed.timestamp)}`}
                              secondaryTypographyProps={{
                                component: 'span',
                                typography: 'caption',
                                color: 'text.disabled',
                              }}
                            />
                          </ListItemButton>

                          <Stack spacing={2}>
                            <Stack spacing={0.5} direction="row" alignItems="center">
                              <Typography variant="caption" color="text.disabled" noWrap>
                                Ended By:
                              </Typography>
                              <Label variant="outlined" color="info">
                                {
                                  completed.chatAssignLog[completed.chatAssignLog.length - 1]
                                    ?.fromUser?.name
                                }
                              </Label>
                            </Stack>

                            <Stack spacing={1} direction="row" alignItems="center">
                              <Typography variant="caption" color="text.disabled">
                                Reason:
                              </Typography>
                              <Typography
                                variant="body2"
                                noWrap
                                sx={{
                                  fontStyle: 'italic',
                                }}
                              >
                                {completed.chatAssignLog[completed.chatAssignLog.length - 1].reason}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>

                        <Divider sx={{ borderStyle: 'dashed' }} />

                        <Box
                          rowGap={1}
                          display="grid"
                          gridTemplateColumns="repeat(2, 1fr)"
                          sx={{ p: 2 }}
                        >
                          <div>
                            <Label color="info">
                              {completed.chatAssignLog[completed.chatAssignLog.length - 1]?.status}
                            </Label>
                          </div>

                          <Typography variant="subtitle2">
                            {fDateTime(
                              completed.chatAssignLog[completed.chatAssignLog.length - 1]
                                ?.created_at
                            )}
                          </Typography>
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Typography variant="h6" sx={{ p: 3 }}>
                      No completed chats available
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </>
        )}

        {currentTab === 'agents' && (
          <>
            <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
              Agents
            </Typography>
            <Box
              gap={4}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(4, 1fr)',
              }}
            >
              {agentIsSuccess && (
                <>
                  {agents.length > 0 ? (
                    agents.map((agent) => (
                      <Card key={agent.id}>
                        <Label
                          color={agent.socketLastSession?.status == 1 ? 'primary' : 'default'}
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {agent.socketLastSession?.status == 1 ? 'Online' : 'Offline'}
                        </Label>

                        <Stack sx={{ p: 3, pb: 2 }}>
                          <ListItemButton disableGutters>
                            <ListItemAvatar>
                              <Avatar
                                src={userUrl}
                                variant="rounded"
                                sx={{ width: 60, height: 60 }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              sx={{ mb: 1 }}
                              primary={agent.name}
                              secondary={
                                <Box>
                                  <Typography
                                    component="span"
                                    variant="subtitle2"
                                    color="text.disabled"
                                  >
                                    {agent.number}
                                  </Typography>
                                  <Box component="div">
                                    <Typography
                                      component="span"
                                      variant="body1"
                                      color="text.secondary"
                                    >
                                      {agent.email}
                                    </Typography>
                                  </Box>
                                </Box>
                              }
                              primaryTypographyProps={{
                                typography: 'subtitle1',
                              }}
                              secondaryTypographyProps={{
                                component: 'span',
                              }}
                            />
                          </ListItemButton>
                        </Stack>

                        <Divider sx={{ borderStyle: 'dashed' }} />
                        <Box
                          rowGap={1}
                          display="grid"
                          gridTemplateColumns="repeat(1, 1fr)"
                          sx={{ p: 2 }}
                        >
                          <Label
                            color={agent.socketLastSession?.status == 1 ? 'primary' : 'default'}
                          >
                            {agent.socketLastSession?.status == 1 ? 'Active at: ' : 'Last Seen: '}
                            {fDateTime(agent.socketLastSession?.updated_at)}
                          </Label>
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Typography variant="h6" sx={{ p: 3 }}>
                      No pending chats available
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </Container>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Chat History</DialogTitle>
        <DialogContent>
          {selectedChat && selectedChat.chatAssignLog && selectedChat.chatAssignLog.length > 0 ? (
            <Box display="flex" flexDirection="column" gap={2}>
              {selectedChat.chatAssignLog.map((log, index) => (
                <Paper
                  key={index}
                  sx={{
                    p: 3,
                    bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
                  }}
                >
                  <Typography variant="subtitle2">
                    <Typography variant="h6">
                      <Iconify
                        icon="fluent-mdl2:radio-bullet"
                        width={24}
                        height={24}
                        sx={{ mr: 0.5 }}
                      />
                      {log.status}
                    </Typography>
                    {fDateTime(log.created_at)}
                  </Typography>

                  <Divider />
                  {log.status == 'Chat Added' && (
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      <Typography variant="caption">Assigned To:</Typography> {log.fromUser?.name} (
                      {log.fromUser?.email})
                    </Typography>
                  )}

                  {log.status != 'Chat Added' && log.status != 'Chat Ended' && (
                    <TableContainer>
                      <Table
                        size="small"
                        sx={{ border: '1px solid', borderColor: 'divider', mt: 2 }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Transferred from user</TableCell>
                            <TableCell align="left">To user</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">
                              <Typography variant="subtitle1">{log.fromUser?.name}</Typography> (
                              {log.fromUser?.email})
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="subtitle1">{log.toUser?.name}</Typography>(
                              {log.toUser?.email})
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                  {log.status == 'Chat Ended' && (
                    <>
                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        <Typography variant="caption">Ended By:</Typography> {log.fromUser?.name} (
                        {log.fromUser?.email})
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        <Typography variant="caption">Reason:</Typography> {log.reason}
                      </Typography>
                    </>
                  )}
                </Paper>
              ))}
            </Box>
          ) : (
            <DialogContentText>No chat history available</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
