import PropTypes from 'prop-types';
import { Stack, InputAdornment, TextField, MenuItem, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
// sections
import Iconify from '../../../../theme/sections/iconify';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

BillingTableToolbar.propTypes = {
  isFiltered: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onDateFilter: PropTypes.func,
  onFilterEndDate: PropTypes.func,
  onFilterStartDate: PropTypes.func,
  filterEndDate: PropTypes.instanceOf(Date),
  filterStartDate: PropTypes.instanceOf(Date),
};

export default function BillingTableToolbar({
  isFiltered,
  onResetFilter,
  onDateFilter,
  filterStartDate,
  filterEndDate,
  onFilterEndDate,
  onFilterStartDate,
}) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <DatePicker
        label="Start date"
        value={filterStartDate}
        onChange={onFilterStartDate}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />

      <DatePicker
        label="End date"
        value={filterEndDate}
        onChange={onFilterEndDate}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />

      <Button
        size="medium"
        variant="soft"
        sx={{ flexShrink: 0 }}
        startIcon={<Iconify icon="tabler:message-2-search" />}
        onClick={onDateFilter}
      >
        Filter
      </Button>
      {isFiltered && (
        <Button
          size="medium"
          variant="soft"
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Clear
        </Button>
      )}
    </Stack>
  );
}
