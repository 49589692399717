import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, Button, Divider, Alert, TextField, Typography } from '@mui/material';
// sections
import Iconify from '../../theme/sections/iconify';
import { useSnackbar } from '../../theme/sections/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../theme/sections/hook-form';

// ----------------------------------------------------------------------

export default function CreatePollForm() {
  //add dynamic fields for options
  const handleAdd = () => {
    append({
      options: [],
    });
  };
  //remove dynamic field for options
  const handleRemove = (i) => {
    remove(i);
  };

  // validation
  const validationSchema = Yup.object().shape({
    options: Yup.string(),
  });

  //useform default values
  const defaultValues = {
    options: '',
  };

  // functions to build form returned by useForm() and useFieldArray() hooks
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const { control, setValue, setError, handleSubmit, formState, clearErrors, reset } = methods;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    name: 'options',
    control,
  });

  // media handling

  //form submission
  const onSubmit = (data) => {
    //console.log(JSON.stringify(data));
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

              <RHFTextField name="name" label="Poll Name" />
              <RHFTextField name="question" label="Ask a question" multiline rows={3} />
              {fields.length == process.env.REACT_APP_POLL_OPTIONS_LIMIT && (
                <Alert severity="warning">Only 5 options can be added.</Alert>
              )}

              <Grid item container spacing={2}>
                {fields.length < process.env.REACT_APP_POLL_OPTIONS_LIMIT && (
                  <Button
                    size="large"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={handleAdd}
                    sx={{ flexShrink: 0 }}
                  >
                    Add Options
                  </Button>
                )}
                {fields.map((item, i) => (
                  <Grid item key={item.id} container spacing={2}>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                      <Controller
                        name={`options[${i}].name`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            label={i + 1 + '. Options'}
                            {...field}
                            fullWidth
                            error={!!error}
                            helperText={errors.options?.[i]?.name.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                      <Button
                        onClick={() => handleRemove(i)}
                        startIcon={<Iconify icon="eva:trash-2-outline" />}
                        variant="contained"
                        color="error"
                        sx={{ my: 1 }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Stack>
            <Divider light sx={{ mt: 3 }} />

            <Stack direction="row" spacing={1.5} sx={{ mt: 3 }} justifyContent="flex-end">
              <LoadingButton type="submit" variant="contained" size="large">
                Create Poll
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
