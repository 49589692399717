import { useEffect, useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Link } from 'react-router-dom';
// @mui
import {
  Typography,
  Autocomplete,
  InputAdornment,
  Stack,
  Box,
  Card,
  CardHeader,
  Chip,
  Grid,
  CardContent,
  Paper,
  Avatar,
} from '@mui/material';
// utils
import axios from '../../../theme/utils/axios';
// sections
import Label from '../../../theme/sections/label';
import Image from '../../../theme/sections/image';
import Iconify from '../../../theme/sections/iconify';
import { CustomTextField } from '../../../theme/sections/custom-input';
import SearchNotFound from '../../../theme/sections/search-not-found';
// redux
import { useSelector } from 'react-redux';
import SvgColor from 'src/theme/sections/svg-color/SvgColor';
import { alpha, styled } from '@mui/system';

// ----------------------------------------------------------------------

export default function AddMemberForm() {
  const [searchMembers, setSearchMembers] = useState('');
  const [enteredValue, setEnteredValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [groups, setGroups] = useState('');
  // user details
  const { user } = useSelector((state) => state.auth);
  const config = {
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  };

  const handleChangeSearch = async (value) => {
    try {
      setSearchMembers(value);
      if (value) {
        const response = await axios.post('/members/search', { search: value }, config);
        if (response.data) {
          setSearchResults(response.data);
          //console.log(response.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchKeyUp = (event, id) => {
    if (event.key === 'Enter') {
      searchGroups(enteredValue);
    }
  };

  const handleSearchClick = (id) => {
    searchGroups(id);
  };

  const searchGroups = async (id) => {
    const result = await axios.get('/members/search/' + id, config);
    setGroups(result);
  };

  const StyledOverlay = styled('div')(({ theme }) => ({
    top: 0,
    left: 0,
    zIndex: 8,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: alpha(theme.palette.grey[900], 0.64),
  }));

  return (
    <Card>
      <CardHeader
        title="Member Profile Search"
        subheader="Search member and its respective groups details"
      />
      <CardContent>
        <Stack spacing={1}>
          <Autocomplete
            autoHighlight
            popupIcon={null}
            onInputChange={(event, value) => handleChangeSearch(value)}
            options={searchResults}
            filterOptions={(options) => options} //for multiple search
            getOptionLabel={(member) => (member.name ? member.name : '')}
            noOptionsText={<SearchNotFound query={searchMembers} />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => (value?.id ? setEnteredValue(value.id) : setGroups(''))}
            componentsProps={{
              popper: {
                sx: {
                  width: `400px !important`,
                },
              },
              paper: {
                sx: {
                  '& .MuiAutocomplete-option': {
                    px: `8px !important`,
                  },
                },
              },
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                width={400}
                placeholder="Type member name or number here..."
                onKeyUp={(event) => handleSearchKeyUp(event, event.target.value)}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            renderOption={(props, member, { inputValue }) => {
              const { id, name, number, image } = member;
              return (
                <Link
                  style={{ textDecoration: 'none' }}
                  onClick={() => handleSearchClick(id)}
                  key={id}
                >
                  <li {...props} style={{ border: '1px dashed #f2f2f2' }}>
                    <Image
                      alt={name}
                      src={image ? image : require('../../../assets/profile/user_dp.png')}
                      sx={{ width: 48, height: 48, borderRadius: 1, flexShrink: 0, mr: 1.5 }}
                    />

                    <Typography component="span" variant="subtitle2" color="primary">
                      {name}
                      <br />
                      <Label color="success">{number}</Label>
                    </Typography>
                  </li>
                </Link>
              );
            }}
          />
        </Stack>

        <Box
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)',
          }}
          sx={{ mt: 4 }}
        >
          {groups &&
            groups.data.groupInfo.map((group) => (
              <Card sx={{ textAlign: 'center', boxShadow: 8 }} key={group.id}>
                {/* <CardHeader title={group.name} sx={{ color: 'primary.main' }} /> */}
                <Box sx={{ position: 'relative' }}>
                  <SvgColor
                    src="..//assets/shape_avatar.svg"
                    sx={{
                      width: 144,
                      height: 62,
                      zIndex: 10,
                      left: 0,
                      right: 0,
                      bottom: -26,
                      mx: 'auto',
                      position: 'absolute',
                      color: 'background.paper',
                    }}
                  />
                  <Avatar
                    alt={name}
                    src={
                      groups.data.image
                        ? groups.data.image
                        : require('../../../assets/profile/user_dp.png')
                    }
                    sx={{
                      width: 64,
                      height: 64,
                      zIndex: 11,
                      left: 0,
                      right: 0,
                      bottom: -32,
                      mx: 'auto',
                      position: 'absolute',
                    }}
                  />
                  <StyledOverlay />
                  <Image
                    src={
                      group.image ? group.image : require('../../../assets/profile/group_dp.png')
                    }
                    alt={group.name}
                    ratio="16/9"
                  />
                </Box>

                <Typography variant="h5" sx={{ mt: 6, color: 'primary.main' }}>
                  {group.name}
                </Typography>
                <Typography variant="subtitle1">{groups.data.name}</Typography>
                {/* <Typography variant="caption">
                  {groups.data.number}
                </Typography> */}

                {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Member since: 12-12-23
                </Typography> */}

                <Chip
                  sx={{ mb: 2 }}
                  size="small"
                  variant="filled"
                  icon={<Iconify width={24} icon="ic:outline-contact-phone" />}
                  label={groups.data.number}
                  color="success"
                />
              </Card>
            ))}
        </Box>
      </CardContent>
    </Card>
  );
}
