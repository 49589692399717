import PropTypes from 'prop-types';
// @mui
import { Card, Typography, Box } from '@mui/material';
// utils
import { fNumber, fShortenNumber } from '../../../theme/utils/formatNumber';
import Iconify from 'src/theme/sections/iconify/Iconify';
import { fDate } from 'src/theme/utils/formatTime';

// ----------------------------------------------------------------------

WidgetSummary.propTypes = {
  icon: PropTypes.node,
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
};

export default function WidgetSummary({ title, total, color, icon, sx, ...other }) {
  return (
    <Card
      sx={{
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 1,
        pl: 1.5,
        ...sx,
        border: 0.1,
        borderColor: color == 'grey' ? `${color}.600` : `${color}.main`,
        boxShadow: 3,
        backgroundColor: color == 'grey' ? `${color}.500` : `${color}.main`,
      }}
      {...other}
    >
      <div>
        <Typography variant="h5">{fNumber(total)}</Typography>

        <Typography variant="subtitle2">{title}</Typography>

        {/* <Typography variant="caption">Outgoing</Typography> */}
      </div>

      <Box
        sx={{
          width: 40,
          height: 40,
          lineHeight: 0,
          borderRadius: '30%',
          bgcolor: 'background.neutral',
        }}
      >
        <Iconify
          icon={icon}
          width="100%"
          height="100%"
          color={color == 'grey' ? `${color}.700` : `${color}.dark`}
        />
      </Box>
    </Card>
  );
}
